import type { Dispatchable, Thunk } from '@/bootstrap/thunks';
import type { OnyxRfq } from '@/neos/business/rfq/rfqOnyxModel';
import type { NeosActionCreators } from '../neosActionCreators';
import type { PriceUnitType, Quote } from '../neosModel';
import { fromQuoteMappers } from '../rfq/quotes/quoteMappers';
import type { UnitTypeQuotes } from '../rfq/rfqOnyxModel';

export function createNeosIntegrateRfqTraderQuotesDifferenceNotificationThunk(
  rfqId: string,
  onyxNotificationRfq: OnyxRfq,
): Thunk {
  return function neosIntegrateRfqTraderQuotesDifferenceNotificationThunk(
    dispatch,
    getState,
    { actionCreators, selectors },
  ) {
    const appState = getState();

    if (!selectors.isStatusAfterPricedIncluded(appState, rfqId, selectors)) {
      return;
    }

    const rfqIsOpen = selectors.tabExists(appState.ui.tabManagement, rfqId);
    if (!rfqIsOpen) {
      return;
    }

    const isTrader = selectors.isTrader(appState);
    if (!isTrader) {
      return;
    }

    const { legQuotes, strategyQuotes, strategyIds } = selectors.getRfqData(appState, rfqId);
    const dispatchables: Array<Dispatchable> = [];
    const { legQuotes: notificationLegQuotes, strategyQuotes: notificationStrategyQuotes } =
      onyxNotificationRfq;

    strategyIds.forEach(strategyId => {
      const { strategyType, legIds, priceUnitType } = selectors.getStrategyData(
        appState,
        strategyId,
      );
      const { availablePriceUnitTypes } = selectors.getStrategyDefinition(
        appState.referenceData,
        strategyType,
      );

      // check if strategy has multiple price unit types (conversion possible we compare using the quotes map)
      if (availablePriceUnitTypes.length > 1) {
        // compare at strategy level

        dispatchables.push(
          ...compareQuotesUsingQuoteMaps(
            strategyQuotes,
            notificationStrategyQuotes,
            priceUnitType,
            strategyId,
            actionCreators.neos,
            rfqId,
          ),
        );

        // strategy is multi leg compare at leg level as well
        if (legIds.length > 1) {
          legIds.forEach(legId =>
            dispatchables.push(
              ...compareQuotesUsingQuoteMaps(
                legQuotes,
                notificationLegQuotes,
                priceUnitType,
                legId,
                actionCreators.neos,
                rfqId,
              ),
            ),
          );
        }
      }
      // strategy has one price unit type (conversion not possible we compare using the quotes directly)
      else {
        // compare at strategy level
        const strategyQuote = selectors.getQuote(appState, strategyId);
        const notificationStrategyQuote = fromQuoteMappers.mapFromOnyxQuote(
          { type: 'Strategy', id: strategyId },
          onyxNotificationRfq.strategies.find(s => s.uuid === strategyId)?.quote,
        );

        dispatchables.push(
          ...compareQuotesDirectly(
            strategyQuote,
            notificationStrategyQuote,
            strategyId,
            actionCreators.neos,
            rfqId,
          ),
        );

        // if strategy is multi leg compare at leg level as well
        if (legIds.length > 1) {
          legIds.forEach(legId => {
            const legQuote = selectors.getQuote(appState, legId);
            const notificationLegQuote = fromQuoteMappers.mapFromOnyxQuote(
              { type: 'Leg', id: legId },
              onyxNotificationRfq?.strategies
                .find(s => s.uuid === strategyId)
                ?.legs?.find(l => l.uuid === legId)?.quote,
            );
            dispatchables.push(
              ...compareQuotesDirectly(
                legQuote,
                notificationLegQuote,
                legId,
                actionCreators.neos,
                rfqId,
              ),
            );
          });
        }
      }
    });

    if (dispatchables.length) {
      dispatch(
        dispatchables,
        actionCreators.neos.rfqUiCrudActions.update(rfqId, { showWarningModal: true }),
      );
    }
  };
}

function findSharedUnitType(
  masterStrategyPriceUnitType: PriceUnitType,
  current: UnitTypeQuotes | undefined,
  notification: UnitTypeQuotes | undefined,
): PriceUnitType | undefined {
  if (!current || !notification) {
    return undefined;
  }
  const currentUnitTypes = Object.keys(current);
  const notificationUnitTypes = Object.keys(notification);
  if (
    currentUnitTypes.includes(masterStrategyPriceUnitType) &&
    notificationUnitTypes.includes(masterStrategyPriceUnitType)
  ) {
    return masterStrategyPriceUnitType;
  }
  currentUnitTypes.forEach(currentType => {
    const foundType = notificationUnitTypes.find(type => type === currentType);
    if (foundType) {
      return foundType;
    }
  });
  return undefined;
}

function extractValueFromQuote(
  Quote: UnitTypeQuotes,
  unit: PriceUnitType,
  bidAsk: 'BID' | 'ASK',
): number | undefined {
  return bidAsk === 'ASK'
    ? Quote[unit]?.traderPrice.ask?.value
    : Quote[unit]?.traderPrice.bid?.value;
}

function compareQuotesUsingQuoteMaps(
  currentQuotes: Record<string, UnitTypeQuotes> | undefined,
  notificationQuotes: Record<string, UnitTypeQuotes> | undefined,
  masterStrategyPriceUnitType: PriceUnitType,
  legIdOrStratId: string,
  actionCreators: NeosActionCreators,
  rfqId: string,
): Array<Dispatchable> {
  const dispatchables: Array<Dispatchable> = [];

  if (!currentQuotes || !notificationQuotes) {
    return [];
  }

  const sharedUnitType = findSharedUnitType(
    masterStrategyPriceUnitType,
    currentQuotes[legIdOrStratId],
    notificationQuotes[legIdOrStratId],
  );
  if (sharedUnitType) {
    const currentBid = extractValueFromQuote(currentQuotes[legIdOrStratId], sharedUnitType, 'BID');
    const notificationBid = extractValueFromQuote(
      notificationQuotes[legIdOrStratId],
      sharedUnitType,
      'BID',
    );
    const currentAsk = extractValueFromQuote(currentQuotes[legIdOrStratId], sharedUnitType, 'ASK');
    const notificationAsk = extractValueFromQuote(
      notificationQuotes[legIdOrStratId],
      sharedUnitType,
      'ASK',
    );
    if (currentAsk && currentAsk !== notificationAsk) {
      dispatchables.push(
        actionCreators.createTraderAskNotificationChangesCrudAction.upsert(
          {
            rfqId,
            id: legIdOrStratId,
          },
          { oldValue: currentAsk, newValue: notificationAsk },
        ),
      );
    }
    if (currentBid && currentBid !== notificationBid) {
      dispatchables.push(
        actionCreators.createTraderBidNotificationChangesCrudAction.upsert(
          {
            rfqId,
            id: legIdOrStratId,
          },
          { oldValue: currentBid, newValue: notificationBid },
        ),
      );
    }
  }

  return dispatchables;
}

function compareQuotesDirectly(
  currentQuote: Quote | undefined,
  notificationQuote: Quote | undefined,
  legIdOrStratId: string,
  actionCreators: NeosActionCreators,
  rfqId: string,
): Array<Dispatchable> {
  const dispatchables: Array<Dispatchable> = [];
  if (!currentQuote || !notificationQuote) {
    return [];
  }
  if (currentQuote.traderAsk && currentQuote.traderAsk !== notificationQuote.traderAsk) {
    dispatchables.push(
      actionCreators.createTraderAskNotificationChangesCrudAction.upsert(
        {
          rfqId,
          id: legIdOrStratId,
        },
        { oldValue: currentQuote.traderAsk, newValue: notificationQuote.traderAsk },
      ),
    );
  }
  if (currentQuote.traderBid && currentQuote.traderBid !== notificationQuote.traderBid) {
    dispatchables.push(
      actionCreators.createTraderBidNotificationChangesCrudAction.upsert(
        {
          rfqId,
          id: legIdOrStratId,
        },
        { oldValue: currentQuote.traderBid, newValue: notificationQuote.traderBid },
      ),
    );
  }
  return dispatchables;
}
