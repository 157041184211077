import type { Selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';

export type AllocRetrieveCashflowsButtonModel = {
  isDisplayed: boolean;
};

export function getAllocRetrieveCashflowsButtonModel(
  state: AppState,
  rfqId: string,
  selectors: Selectors,
): AllocRetrieveCashflowsButtonModel {
  const { status } = selectors.getRfqData(state, rfqId);
  const isStatusBookedOrTradeCompleted = status === 'BOOKED' || status === 'TRADE_COMPLETED';

  return {
    isDisplayed:
      selectors.shouldDisplayOtcAllocationsCashflows(state, rfqId, selectors) &&
      isStatusBookedOrTradeCompleted,
  };
}
