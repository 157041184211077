import { selectors } from '@/bootstrap/selectors.ts';
import { NumericInput } from '@/neos/components/share/numericInput';
import { useAppSelector } from '@/bootstrap/hooks.ts';
import { getCashflowsModel } from '@/neos/components/rfq/postNego/allocs/otcAllocations/secondaryAllocations/cashflows/getCashflowsModel.ts';

interface CashflowsProps {
  rfqId: string;
  legId: string;
}

export function Cashflows({ rfqId, legId }: CashflowsProps) {
  const { indexCashFlow, feeCashFlow, interestCashFlow, isDisplayed } = useAppSelector(state =>
    getCashflowsModel(state, rfqId, legId, selectors),
  );

  if (!isDisplayed) {
    return null;
  }

  return (
    <div className="d-flex gap-1">
      <div>
        <label className="me-3">Index Cash Flow</label>
        <NumericInput value={indexCashFlow} readOnly data-e2e="alloc-index-cashflow" />
      </div>
      <div>
        <label className="me-3">Fee Cash Flow</label>
        <NumericInput value={feeCashFlow} readOnly data-e2e="alloc-fee-cashflow" />
      </div>
      <div>
        <label className="me-3">Interest Cash Flow</label>
        <NumericInput value={interestCashFlow} readOnly data-e2e="alloc-interest-cashflow" />
      </div>
    </div>
  );
}
