import {
  bloombergCodeColumnAliases,
  brokerAliases,
  counterpartAliases,
  internalPortfolioAliases,
  portfolioAliases,
  quantityColumnAliases,
  sanitizeArrayOfColumnNames,
  sanitizeColumnName,
  spotColumnAliases,
  spotNetAliases,
  spotNetUnitAliases,
  spotUnitAliases,
  typeAliases,
  wayAliases,
} from '@/neos/business/rfq/strategy/leg/product/els/ElsColumnsImportUtils.ts';

const importedEquityHEdgeComponentColumns = [
  'bloombergCode',
  'quantity',
  'spot',
  'spotUnit',
  'spotNet',
  'spotNetUnit',
  'counterpart',
  'broker',
  'portfolio',
  'internalPortfolio',
  'type',
  'way',
] as const;

export type ImportedEquityHEdgeComponentColumns =
  (typeof importedEquityHEdgeComponentColumns)[number];

export type ImportedEquityHedgeData = {
  [K in ImportedEquityHEdgeComponentColumns]: string | undefined;
};

export function elsBuyAndSellImportApproximateImportedKeys(
  importedDataWithCamelCaseKeys: Record<string, string>[],
): ImportedEquityHedgeData[] {
  const sanitizedKeys: ImportedEquityHedgeData[] = [];
  importedDataWithCamelCaseKeys.map(line => {
    const objectParsedFromLine: ImportedEquityHedgeData = {
      bloombergCode: undefined,
      quantity: undefined,
      spot: undefined,
      spotUnit: undefined,
      spotNet: undefined,
      spotNetUnit: undefined,
      counterpart: undefined,
      broker: undefined,
      portfolio: undefined,
      internalPortfolio: undefined,
      type: undefined,
      way: undefined,
    };
    Object.entries(line).forEach(([key, value]) => {
      const column = sanitizeColumnName(key);
      for (const importedDataColumnName of importedEquityHEdgeComponentColumns) {
        if (sanitizeArrayOfColumnNames(columnMapping[importedDataColumnName]).includes(column)) {
          objectParsedFromLine[importedDataColumnName] = value;
          return;
        }
      }
    });
    sanitizedKeys.push(objectParsedFromLine);
  });
  return sanitizedKeys;
}

const columnMapping: Record<ImportedEquityHEdgeComponentColumns, string[]> = {
  bloombergCode: bloombergCodeColumnAliases,
  broker: brokerAliases,
  way: wayAliases,
  type: typeAliases,
  spot: spotColumnAliases,
  counterpart: counterpartAliases,
  portfolio: portfolioAliases,
  internalPortfolio: internalPortfolioAliases,
  quantity: quantityColumnAliases,
  spotNet: spotNetAliases,
  spotNetUnit: spotNetUnitAliases,
  spotUnit: spotUnitAliases,
};
