import { actionCreators } from '@/bootstrap/actions';
import type { FC } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '@/bootstrap/hooks.ts';
import { getAllocRetrieveCashflowsButtonModel } from '@/neos/components/rfq/postNego/allocs/retrieveCashflowsButton/retrieveCashflowsButtonModel.ts';
import { selectors } from '@/bootstrap/selectors.ts';

type RetrieveCashflowsButtonProps = {
  rfqId: string;
};

export const RetrieveCashflowsButton: FC<RetrieveCashflowsButtonProps> = ({ rfqId }) => {
  const dispatch = useDispatch();

  const { isDisplayed } = useAppSelector(state =>
    getAllocRetrieveCashflowsButtonModel(state, rfqId, selectors),
  );

  if (!isDisplayed) {
    return null;
  }

  return (
    <button
      className="btn btn-outline-primary"
      data-testid="alloc-retrieve-cashflows-button"
      onClick={() => dispatch(actionCreators.neos.createAllocRetrieveCashflowsAction(rfqId))}
    >
      Retrieve Cash Flows
    </button>
  );
};
