import { thunks } from '@/bootstrap/thunks';
import type { Els } from '@/neos/business/rfq/strategy/leg/product/productModel';
import { useDispatch } from 'react-redux';

type ElsBuyAndSellExportProps = {
  rfqId: string;
  product: Els;
};

export const ElsBuyAndSellExport = ({ rfqId, product }: ElsBuyAndSellExportProps) => {
  const dispatch = useDispatch();
  const isButtonDisabled = !product.equityHedge?.equityHedgeComponents.length;

  return (
    <button
      data-e2e="els-buy-and-sell-export"
      className={`btn btn-discreet-primary sgbs-btn-primary ${isButtonDisabled && 'disabled'}`}
      onClick={() => {
        if (!isButtonDisabled) {
          dispatch(thunks.neos.createExportElsBuyAndSellCompositionThunk(rfqId, product.uuid));
        }
      }}
      disabled={isButtonDisabled}
    >
      Export <i className="icon icon-sm ms-2">file_download</i>
    </button>
  );
};
