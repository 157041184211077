import type { SgmeHttp } from '@/util/http/sgmeHttpBase';
import type { Observable } from 'rxjs';
import type { SearchByBookingIdApi } from '../epics/searchByBookingIdEpic';
import type { OnyxRfq, OnyxTransaction } from '../neosOnyxModel';
import type { BulkCancelReason } from './blotterModel';
import type { CancelRfqsApi, CancelTransactionApiModel } from './epics/getCancelRfqsEpic';
import type { LoadRfqsApi } from './epics/getLoadRfqsEpic';

export const createLoadRfqsApi = (http: SgmeHttp): LoadRfqsApi => ({
  loadRfqs(
    from,
    to,
    workflows,
    underlyingIds,
    counterpartIds,
    statuses,
    strategyTypes,
    maturityDateFrom,
    maturityDateTo,
  ): Observable<OnyxRfq[]> {
    let route = `api/rfq/view/blotter?from=${from}&to=${to}&workflows=${workflows}`;
    if (underlyingIds.length > 0) {
      route += `&underlyingIds=${underlyingIds}`;
    }
    if (counterpartIds.length > 0) {
      route += `&salesCounterpartyIds=${counterpartIds}`;
    }
    if (statuses.length > 0) {
      route += `&statuses=${statuses}`;
    }
    if (strategyTypes.length > 0) {
      route += `&strategyTypes=${strategyTypes}`;
    }
    if (maturityDateFrom?.length && maturityDateTo?.length) {
      route += `&maturityDateFrom=${maturityDateFrom}&maturityDateTo=${maturityDateTo}`;
    }

    return http.get<OnyxRfq[]>({ url: route });
  },
});

export const createSearchByBookingIdApi = (http: SgmeHttp): SearchByBookingIdApi => ({
  loadRfqs(bookingId): Observable<OnyxTransaction[]> {
    const route = `api/workflow-neos/transactions/bookingIds?bookingIds=${bookingId}`;

    return http.get<OnyxTransaction[]>({ url: route });
  },
});

export const createCancelRfqsApi = (http: SgmeHttp): CancelRfqsApi => ({
  cancelRfqs(transactions: CancelTransactionApiModel, reason: BulkCancelReason): Observable<void> {
    const route = `api/workflow-neos/bulk/${reason}`;
    return http.post<void>({
      url: route,
      body: transactions,
    });
  },
});
