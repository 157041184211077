import type { Selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import { distinct } from '@/util/array/arrayUtils';
import type { ForexRate } from '../../../../business/neosOnyxModel';
import type { ForexTo, ForexValue } from './Forex';

export interface ForexModel {
  forexValues: ForexValue[];
  fromCurrencies: string[];
  isEls: boolean;
  isReadonlyAtCurrentWorkflow: boolean;
}

export function getForexModel(state: AppState, rfqId: string, selectors: Selectors): ForexModel {
  const { getForexRates } = selectors;

  // NOTE: all the following comments are related to #CLINEG-6043 => temporary remove edit conditions
  // const { pivotNotionalCurrency } = getRfqData(state, rfqId);
  const forexRates = getForexRates(state, rfqId);
  const fromCurrencies = distinct(forexRates.map(({ from }) => from)).sort();
  const toCurrencies = distinct(forexRates.map(({ to }) => to)).sort();

  const getForexValues = (toCurrency: string) =>
    fromCurrencies.map(
      fromCurrency => getForexValuesByCurrency(forexRates, { toCurrency, fromCurrency }),
      // getForexValuesByCurrency(forexRates, { toCurrency, fromCurrency }, pivotNotionalCurrency),
    );

  const forexValues = toCurrencies.map(
    (toCurrency): ForexValue => ({
      toCurrency,
      forexValues: getForexValues(toCurrency),
    }),
  );

  const strategyIds = selectors.getRfqData(state, rfqId).strategyIds;
  const isEls = strategyIds.some(id => selectors.isElsStrategy(state, id, selectors));
  const someStrategiesAreReadonly = strategyIds.some(strategyId =>
    selectors.isReadOnlyAtCurrentWorkflow(state, strategyId, selectors),
  );

  return {
    fromCurrencies,
    forexValues,
    isEls,
    isReadonlyAtCurrentWorkflow: someStrategiesAreReadonly,
  };
}

export function getForexValuesByCurrency(
  forexRates: ForexRate[],
  { toCurrency, fromCurrency }: { toCurrency: string; fromCurrency: string },
  // pivotCurrency: string | undefined,
): ForexTo | undefined {
  const forexRateFoundByToAndFrom = getForexRateFoundByToAndFrom(forexRates, {
    toCurrency,
    fromCurrency,
  });

  if (forexRateFoundByToAndFrom) {
    const isEditable = true;
    // const isEditable = getIsForexEditable(forexRateFoundByToAndFrom, { pivotCurrency, toCurrency });

    return {
      fromCurrency,
      isEditable,
      fromCurrencyValue: forexRateFoundByToAndFrom.rate,
    };
  }

  return undefined;
}

export function getIsForexEditable(
  { from, rate }: ForexRate,
  {
    pivotCurrency,
    toCurrency,
  }: {
    pivotCurrency: string | undefined;
    toCurrency: string;
  },
): boolean {
  const isRateGreaterOrEqualToOne = rate === undefined || rate >= 1;
  const isCurrencyPairContainingPivot = pivotCurrency === toCurrency || pivotCurrency === from;
  return isCurrencyPairContainingPivot && isRateGreaterOrEqualToOne;
}

export function getForexRateFoundByToAndFrom(
  forexRates: ForexRate[],
  { toCurrency, fromCurrency }: { toCurrency: string; fromCurrency: string },
): ForexRate | undefined {
  return forexRates.find(({ to, from }) => to === toCurrency && from === fromCurrency);
}
