import { services } from '@/bootstrap/services';
import {
  getEndOfLastMonth,
  getEndOfLastWeek,
  getStartOfLastMonth,
  getStartOfLastWeek,
  getStartOfThisMonth,
  getStartOfThisWeek,
  getThreeDaysAgo,
  getToday,
  getYesterday,
} from '@/util/date/dateFormatHelper';
import type { Workflow } from '../neosOnyxModel';
import type { UserPreferencesState } from '../ui/userPreferences/userPreferencesUiState';
import type { BlotterAction } from './blotterActions';
import type { NeosBlotterRfq, SubfilterType } from './blotterModel';
import type { BlotterState } from './blotterState';

const workflowsStorageKey = 'blotter-workflows';

const defaultBlotterState: BlotterState = {
  rfqs: [],
  from: loadFromDateFromLocalStorage(),
  to: loadToDateFromLocalStorage(),
  uiFrom: loadFromDateFromLocalStorage(),
  uiTo: loadToDateFromLocalStorage(),
  workflows: loadWorkflowsToLocalStorage(),
  lastFilteredOutNotificationDate: undefined,
  typeaheadValue: [],
  activeTypeaheadValues: [],
  lastRequestTime: '',
  subfilter: 'ALL',
  maturityDateFrom: '',
  maturityDateTo: '',
};
export const blotterReducer = (
  state: BlotterState = defaultBlotterState,
  action: BlotterAction,
): BlotterState => {
  switch (action.type) {
    case 'BLOTTER_RFQS_RECEIVED':
      return addBlotterRfqs(state, action.rfqs);
    case 'BLOTTER_RFQ_RECEIVED':
      return addBlotterRfq(state, action.rfq);
    case 'BLOTTER_RFQ_UPDATED':
      return updateBlotterRfq(state, action.rfq);
    case 'CHANGE_BLOTTER_FROM_DATE':
      return { ...state, uiFrom: action.from };
    case 'CHANGE_BLOTTER_TO_DATE':
      return { ...state, uiTo: action.to };
    case 'CHANGE_BLOTTER_WORKFLOWS':
      saveWorkflowsToLocalStorage(action.workflows);
      return { ...state, workflows: action.workflows };
    case 'CHANGE_BLOTTER_SUBFILTER':
      return changeBlotterSubfilter(state, action.subfilter);
    case 'BLOTTER_RFQ_NOTIFICATION_RECEIVED_ACTION':
      return { ...state, lastFilteredOutNotificationDate: new Date() };
    case 'BLOTTER_RFQ_NOTIFICATION_RESET_ACTION':
      return { ...state, lastFilteredOutNotificationDate: undefined };
    case 'CHANGE_BLOTTER_TYPEAHEAD':
      return { ...state, typeaheadValue: action.typeaheadValues };
    case 'CHANGE_BLOTTER_ACTIVE_TYPEAHEAD':
      return { ...state, activeTypeaheadValues: action.typeaheadValues };
    case 'CHANGE_BLOTTER_REQUEST_TIME':
      return { ...state, lastRequestTime: action.time };
    case 'CHANGE_BLOTTER_MATURITY_DATE_RANGE':
      return {
        ...state,
        maturityDateFrom: action.maturityDateFrom,
        maturityDateTo: action.maturityDateTo,
      };
    default:
      return state;
  }
};

function addBlotterRfqs(state: BlotterState, newRfqs: NeosBlotterRfq[]): BlotterState {
  return {
    ...state,
    rfqs: newRfqs,
    from: state.uiFrom,
    to: state.uiTo,
    lastFilteredOutNotificationDate: undefined,
  };
}

function addBlotterRfq(state: BlotterState, newRfq: NeosBlotterRfq): BlotterState {
  return { ...state, rfqs: [...state.rfqs, newRfq] };
}

function updateBlotterRfq(state: BlotterState, newRfq: NeosBlotterRfq): BlotterState {
  const rfqIndex = state.rfqs.findIndex(rfq => rfq.uuid === newRfq.uuid);
  state.rfqs[rfqIndex] = newRfq;
  return state;
}

function saveWorkflowsToLocalStorage(workflows: Workflow[]) {
  services.localStorage.setLocalStorageItem(workflowsStorageKey, JSON.stringify(workflows));
}

function loadFromDateFromLocalStorage(): string {
  const userPreferences =
    services.localStorage.getLocalStorageItem<UserPreferencesState>('userPreferences');

  switch (userPreferences?.blotterDefaultTimerange) {
    case 'TODAY':
      return getToday();
    case 'LAST_3_DAYS':
      return getThreeDaysAgo();
    case 'YESTERDAY':
      return getYesterday();
    case 'THIS_WEEK':
      return getStartOfThisWeek();
    case 'LAST_WEEK':
      return getStartOfLastWeek();
    case 'THIS_MONTH':
      return getStartOfThisMonth();
    case 'LAST_MONTH':
      return getStartOfLastMonth();
    default:
      return getBlotterDefaultTimeRange();
  }
}

function loadToDateFromLocalStorage(): string {
  const userPreferences =
    services.localStorage.getLocalStorageItem<UserPreferencesState>('userPreferences');

  switch (userPreferences?.blotterDefaultTimerange) {
    case 'TODAY':
      return getToday();
    case 'LAST_3_DAYS':
      return getToday();
    case 'YESTERDAY':
      return getYesterday();
    case 'THIS_WEEK':
      return getToday();
    case 'LAST_WEEK':
      return getEndOfLastWeek();
    case 'THIS_MONTH':
      return getToday();
    case 'LAST_MONTH':
      return getEndOfLastMonth();
    default:
      return getBlotterDefaultTimeRange();
  }
}

function getBlotterDefaultTimeRange() {
  return getToday();
}

function loadWorkflowsToLocalStorage(): Workflow[] {
  const workflows = services.localStorage.getLocalStorageItem<Workflow[]>(workflowsStorageKey);
  if (workflows !== null) {
    // Sanity check, don't trust localStorage
    return workflows.every(w => w === 'NEOS' || w === 'ONYX') ? workflows : ['NEOS'];
  }

  return ['NEOS'];
}

function changeBlotterSubfilter(state: BlotterState, subfilter: SubfilterType) {
  return {
    ...state,
    subfilter,
    lastFilteredOutNotificationDate: undefined,
  };
}
