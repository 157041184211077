import type { Thunk } from '@/bootstrap/thunks';
import type { PredealCheck } from '../predealCheck/predealCheckModel';

export function createPredealCheckNotificationReceivedThunk(
  rfqId: string,
  predealCheck: PredealCheck,
): Thunk {
  return function predealCheckNotificationReceivedThunk(
    dispatch,
    getState,
    {
      selectors,
      actionCreators: {
        neos: { pendingPredealChecksCrudActions },
      },
      thunks: {
        neos: { createPredealChecksReceivedThunk },
      },
    },
  ) {
    const state = getState();
    const rfqIsOpen = selectors.tabExists(state.ui.tabManagement, rfqId);
    if (!rfqIsOpen) {
      return;
    }
    const savingDone = selectors.isRfqSavingDone(state, rfqId);
    const { status } = selectors.getRfqData(state, rfqId);

    const canHandleNotificationsAtNewStatus = status === 'NEW' && savingDone === null;
    const canHandleNotification = savingDone || canHandleNotificationsAtNewStatus;

    const dispatchable = canHandleNotification
      ? createPredealChecksReceivedThunk(rfqId, [predealCheck])
      : pendingPredealChecksCrudActions.arrayAdd(rfqId, {
          property: 'pending',
          value: predealCheck,
        });

    dispatch(dispatchable);
  };
}
