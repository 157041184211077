import { CreateAllocSecondaryButton } from '@/neos/components/rfq/postNego/allocs/otcAllocations/otcAllocationFields/createAllocSecondaryEvent/CreateAllocSecondaryButton';
import { If } from '@/neos/components/share/if/if';
import { NeosResizableBox } from '@/neos/components/share/neosResizableBox/NeosResizableBox';
import { Fragment } from 'react';
import { addBorder, type CommonResizableProps } from '../PostNego';
import { ReGeneratePreconfsButton } from './ReGeneratePreconfsButton/ReGeneratePreconfsButton';
import { TriggerMatchingButton } from './TriggerMatchingButton/TriggerMatchingButton';
import { DeltaStockAllocations } from './deltaStockAllocations/DeltaStockAllocations';
import { getAllocationsModel } from './getAllocationsModel';
import { ListedExecutions } from './listedExecutions/ListedExecutions.container';
import { MatchingOrInsertToggle } from './matchingOrInsertToggle/MatchingOrInsertToggle';
import { OtcAllocations } from './otcAllocations/OtcAllocations.container';
import { ImportOtcAllocationsButton } from './otcAllocations/otcAllocationFields/ImportOtcAllocations/ImportOtcAllocations.container';
import { selectors } from '@/bootstrap/selectors.ts';
import { useAppSelector } from '@/bootstrap/hooks.ts';
import { RetrieveCashflowsButton } from '@/neos/components/rfq/postNego/allocs/retrieveCashflowsButton/retrieveCashflowsButton.tsx';

export const Allocations = ({ rfqId, width, onResize, isDeltaPresent }: CommonResizableProps) => {
  const legs = useAppSelector(state => getAllocationsModel(state, rfqId, selectors));
  const hasOtc = legs.strategyLegs.some(s => s.legsModel.some(l => l.isOtc));

  return (
    <div className="d-flex">
      <NeosResizableBox
        width={width}
        height={Infinity}
        resizeHandles={['e']}
        axis="x"
        minConstraints={[window.innerWidth * 0.25, Infinity]}
        maxConstraints={[window.innerWidth * 0.75, Infinity]}
        onResize={(_, d) => onResize(d.size.width)}
        isResizable={isDeltaPresent}
      >
        <div className={`${addBorder(isDeltaPresent)} px-1 h-100`}>
          <div className="d-flex justify-content-between mb-1">
            <label>Strategy Legs Allocations</label>
            <MatchingOrInsertToggle rfqId={rfqId} legs={legs} />
            <div className="d-flex gap-1">
              <RetrieveCashflowsButton rfqId={rfqId} />
              <CreateAllocSecondaryButton rfqId={rfqId} />
              <ReGeneratePreconfsButton rfqId={rfqId} />
              {hasOtc && <ImportOtcAllocationsButton rfqId={rfqId} />}
              <TriggerMatchingButton rfqId={rfqId} legs={legs} />
            </div>
          </div>
          {legs.strategyLegs.map(({ strategyId, legsModel }) => (
            <div className="border p-1 mb-3 overflow-auto" key={strategyId}>
              {legsModel.map(({ legId, isOtc }) => (
                <Fragment key={legId}>
                  {isOtc ? (
                    <OtcAllocations rfqId={rfqId} strategyId={strategyId} legId={legId} />
                  ) : (
                    <ListedExecutions rfqId={rfqId} strategyId={strategyId} legId={legId} />
                  )}
                </Fragment>
              ))}
            </div>
          ))}
        </div>
      </NeosResizableBox>
      <If condition={isDeltaPresent}>
        <div
          className="px-1 overflow-auto"
          style={{ maxWidth: `calc(100vw - ${width}px - 24px)`, width: '100%', minWidth: '600px' }}
        >
          <div className="d-flex justify-content-between mb-1">
            <label>Delta Legs Allocations</label>
            <MatchingOrInsertToggle rfqId={rfqId} legs={legs} isDelta />
            <TriggerMatchingButton rfqId={rfqId} legs={legs} isDelta />
          </div>
          {legs.deltaLegs.map(({ strategyId, legsModel }) => (
            <div className="border p-1 mb-3 overflow-auto" key={strategyId}>
              {legsModel.map(({ legId, isOtc, isUnderlyingTypeDelta }) => (
                <Fragment key={legId}>
                  {isOtc ? (
                    <OtcAllocations
                      key={legId}
                      rfqId={rfqId}
                      strategyId={strategyId}
                      legId={legId}
                    />
                  ) : (
                    <>
                      {isUnderlyingTypeDelta ? (
                        <DeltaStockAllocations
                          rfqId={rfqId}
                          strategyId={strategyId}
                          legId={legId}
                        />
                      ) : (
                        <ListedExecutions rfqId={rfqId} strategyId={strategyId} legId={legId} />
                      )}
                    </>
                  )}
                </Fragment>
              ))}
            </div>
          ))}
        </div>
      </If>
    </div>
  );
};
