import { selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { StepsCommonInfo } from '../stepsCommonInfo';
import styles from './PostNego.module.scss';
import { Recap } from './Recap';
import { Allocations } from './allocs/Allocations';
import { ConfirmationDetails } from './confirmationDetails/ConfirmationDetails';
import { PreAllocs } from './preAllocs/PreAllocs';

export interface PostNegoProps {
  rfqId: string;
}

export interface CommonResizableProps {
  rfqId: string;
  width: number;
  onResize: (newWidth: number) => void;
  isDeltaPresent: boolean;
}

export const PostNego = ({ rfqId }: PostNegoProps) => {
  const [width, setWidth] = useState(window.innerWidth * 0.5 - 6);

  const isDeltaPresent = useSelector((state: AppState) => {
    const hedges = selectors.getHedges(state, { rfqId });
    return hedges.some(
      ({ deltaType }) => deltaType === 'DELTA_EXCHANGE' || deltaType === 'DELTA_EXCHANGE_OTC',
    );
  });

  return (
    <div className={`${styles['post-nego-content']}`}>
      <StepsCommonInfo rfqId={rfqId} />
      <div className={`${styles['post-nego-body']}`}>
        <Recap rfqId={rfqId} width={width} onResize={setWidth} isDeltaPresent={isDeltaPresent} />
        <PreAllocs
          rfqId={rfqId}
          width={width}
          onResize={setWidth}
          isDeltaPresent={isDeltaPresent}
        />
        <Allocations
          rfqId={rfqId}
          width={width}
          onResize={setWidth}
          isDeltaPresent={isDeltaPresent}
        />
        <ConfirmationDetails rfqId={rfqId} />
      </div>
    </div>
  );
};

export function addBorder(isDeltaPresent: boolean) {
  return `${isDeltaPresent ? 'border-end border-xl' : ''}`;
}
