import { type ActionCreators, actionCreators } from '@/bootstrap/actions';
import type { ThunkEpic } from '@/bootstrap/epics';
import { type Thunks, thunks } from '@/bootstrap/thunks';
import type { SgmeHttp } from '@/util/http/sgmeHttpBase';
import { ofType } from 'redux-observable';
import { catchError, mergeMap } from 'rxjs/operators';
import { type Selectors, selectors as allSelectors } from '../../../../bootstrap/selectors';
import { mappers, type ToOnyxMappers } from '../../mappers';
import {
  createCreateTopicApi,
  type CreateTopicApi,
} from '@/neos/business/rfq/apis/createTopicApi.ts';
import type { OnyxError } from '@/neos/business/mappers/error';

export function getCreateTopicEpic(http: SgmeHttp): ThunkEpic {
  const api = createCreateTopicApi(http);
  return createCreateTopicEpic(api, allSelectors, thunks, mappers.toOnyxMappers, actionCreators);
}

export function createCreateTopicEpic(
  api: CreateTopicApi,
  selectors: Selectors,
  { createErrorToasterThunk }: Thunks,
  toOnyxMappers: ToOnyxMappers,
  { neos: { createRequestSubscriptionAction } }: ActionCreators,
): ThunkEpic {
  return (action$, state$) =>
    action$.pipe(
      ofType('CREATE_TOPIC_REQUESTED'),
      mergeMap(({ rfqId }) => {
        return api
          .createTopic(
            rfqId,
            toOnyxMappers.mapToOnyxTransaction(state$.value, rfqId, selectors, toOnyxMappers),
          )
          .pipe(
            mergeMap(() => [
              createRequestSubscriptionAction([
                {
                  notificationTopic: 'TRANSACTION',
                  dataGroupNames: [rfqId],
                },
              ]),
            ]),
            catchError((error: OnyxError) => {
              return [
                createErrorToasterThunk(
                  { message: `An error occured when creating topic for rfqId ${rfqId}` },
                  error,
                ),
              ];
            }),
          );
      }),
    );
}
