import type { Thunk } from '@/bootstrap/thunks.ts';
import type { EquityHedgeComponent } from '@/neos/business/rfq/strategy/leg/product/productModel.ts';
import {
  isElsProduct,
  type Underlying,
} from '@/neos/business/rfq/strategy/leg/product/productModel.ts';
import type { ValidImportedEquityHedgeCompositionData } from '@/neos/business/rfq/strategy/leg/product/els/thunks/createImportElsBuyAndSellCompositionThunk.ts';
import type { Way, WayValues } from '@/neos/business/rfq/rfqData/rfqDataModel.ts';
import { clientWays } from '@/neos/business/rfq/rfqData/rfqDataModel.ts';
import type { EquityHedgeType } from '@/neos/business/rfq/strategy/leg/product/elsProductOnyxModel.ts';
import type { Counterpart } from '@/neos/business/rfq/actorsSetup/counterpart/counterpartModel.ts';
import type { Reference } from '@/neos/business/rfq/reference/referenceModel.ts';
import { DefaultingOptions } from '@/neos/business/rfq/rfqActions.ts';
import { DefaultingScope } from '@/neos/business/rfq/models';

export function createUpdateProductAndReferenceWithImportedEquityHedgeCompositionThunk(
  rfqId: string,
  productId: string,
  underlyings: Underlying[],
  validatedEquityHedgeCompositionData: ValidImportedEquityHedgeCompositionData,
): Thunk {
  return function updateProductAndReferenceWithImportedEquityHedgeCompositionThunk(
    dispatch,
    getState,
    { actionCreators, selectors, services },
  ) {
    const state = getState();

    const product = selectors.getProduct(state, productId);
    const isRfqInternal = selectors.isRfqInternalEls(state, rfqId, selectors);

    if (!isElsProduct(product)) {
      return;
    }

    const newEquityHedgeCompositionDetails2: EquityHedgeComponent[] = [];

    const availableCounterParties = selectors.getAndFormatAvailableClients(state, rfqId, selectors);

    validatedEquityHedgeCompositionData.forEach(line => {
      const underlying = underlyings.find(
        underlying => underlying.bloombergCode === line.bloombergCode,
      );

      if (underlying) {
        const spotCurrency = getSpotCurrency(line.spotUnit, line.spot, underlying.currency);
        const spotNetCurrency = getSpotCurrency(
          line.spotNetUnit,
          line.spotNet,
          underlying.currency,
        );

        const reference: Reference = {
          isSpotConfirmed: undefined,
          underlyingId: underlying.id,
          refSpot: line.spot,
          refSpotUnit: spotCurrency,
          refSpotNet: line.spotNet,
          refSpotNetUnit: spotNetCurrency,
        };

        newEquityHedgeCompositionDetails2.push({
          uuid: services.idCreator.createId(),
          equityType: getEquityTypeKey(line.type, isRfqInternal),
          nominal: undefined,
          counterparty: getLineCounterpart(line.counterpart, availableCounterParties),
          underlyingId: underlying.id,
          way: getClientWay(line.way),
          spot: line.spot ? { value: line.spot, unit: spotCurrency } : undefined,
          spotNet: line.spotNet ? { value: line.spotNet, unit: spotNetCurrency } : undefined,
          quantity: line.quantity,
          broker: line.broker ?? '',
          portfolio: line.portfolio,
          internalPortfolio:
            isRfqInternal && line.internalPortfolio
              ? (line.internalPortfolio as string)
              : undefined,
          bookingId: undefined,
          bookingApplication: undefined,
        });
        dispatch(
          actionCreators.neos.referenceCrudActions.upsert(
            {
              rfqId,
              underlyingId: underlying.id,
            },
            reference,
          ),
        );
      }
    });

    dispatch(
      actionCreators.neos.productCrudActions.update(product.uuid, {
        equityHedge: {
          broker: product.equityHedge?.broker,
          equityType: product.equityHedge?.equityType,
          currency: product.equityHedge?.currency,
          way: product.equityHedge?.way,
          bookedManually: product.equityHedge?.bookedManually,
          counterparty: product.equityHedge?.counterparty,
          equityHedgeComponents: newEquityHedgeCompositionDetails2,
        },
      }),
    );

    dispatch(
      actionCreators.neos.createDefaultRfqRequestedAction(
        rfqId,
        new DefaultingOptions({
          enrichScopes: [
            DefaultingScope.REFERENCE,
            DefaultingScope.SPOT_NET,
            DefaultingScope.EQUITY_HEDGE,
            DefaultingScope.FOREX,
          ],
        }),
      ),
    );
  };
}

function getSpotCurrency(
  spotUnit: string | undefined,
  spot: number | undefined,
  underlyingCurrency: string | undefined,
) {
  if (spot === undefined) {
    return undefined;
  }

  return spotUnit ?? underlyingCurrency;
}

function getClientWay(way: WayValues | undefined): Way | undefined {
  if (way === undefined) {
    return undefined;
  }
  return (Object.keys(clientWays) as Array<Way>).find(key => clientWays[key] === way);
}

function getEquityTypeKey(
  equityType: string | undefined,
  rfqIsInternal: boolean,
): EquityHedgeType | undefined {
  if (rfqIsInternal) {
    if (equityType === 'Internal') {
      return 'INTERNAL';
    }
    return undefined;
  }
  switch (equityType) {
    case 'Back to back':
      return 'BACK_TO_BACK';
    case 'With broker':
      return 'WITH_BROKER';
  }
  return undefined;
}

function getLineCounterpart(
  counterPartName: string | undefined,
  availableCounterparts: Counterpart[],
): Counterpart | undefined {
  return availableCounterparts.find(counterpart => counterpart.name === counterPartName);
}
