import type { EventType } from '@/neos/business/rfq/strategy/leg/legData/legDataModel';
import type { MultipleTypeaheadValue } from '../../components/share/multipleAsyncTypeahead';
import type { Workflow } from '../neosOnyxModel';
import type {
  BlotterRfqNotificationReceivedAction,
  BlotterRfqNotificationResetAction,
  BlotterRfqReceivedAction,
  BlotterRfqsReceivedAction,
  BlotterRfqUpdatedAction,
  ChangeBlotterActiveMultipleCriteriasAction,
  ChangeBlotterFromDateAction,
  ChangeBlotterMaturityDateRangeAction,
  ChangeBlotterMultipleCriteriasAction,
  ChangeBlotterRequestTimeAction,
  ChangeBlotterSubfilterAction,
  ChangeBlotterToDateAction,
  ChangeBlotterWorkflowsAction,
  RequestBlotterRfqsAction,
  RequestBulkCancelRfqsAction,
  RequestCreateSecondaryFromXoneIdAction,
  RequestSearchByBookingIdAction,
} from './blotterActions';
import type { BulkCancelReason, NeosBlotterRfq, SubfilterType } from './blotterModel';
import { createRequestAllBlotterPreconfAction } from './blotterPreconf/blotterPreconfActionsCreators';

export const blotterActionCreators = {
  createBlotterRfqsReceivedAction,
  requestBlotterRfqs,
  changeBlotterFromDate,
  changeBlotterToDate,
  changeBlotterWorkflows,
  changeBlotterMultipleCriterias,
  createBlotterRfqReceivedAction,
  updateBlotterRfq,
  updateBlotterRfqFilteredOutNotificationLastDate,
  resetBlotterRfqFilteredOutNotificationLastDate,
  createRequestBulkCancelRfqsAction,
  changeBlotterRequestTime,
  changeBlotterSubfilter,
  changeBlotterActiveMultipleCriterias,
  createRequestAllBlotterPreconfAction,
  requestSearchByBookingId,
  changeBlotterMaturityDateRange,
  requestCreateSecondaryFromXoneId,
};

export function createBlotterRfqReceivedAction(rfq: NeosBlotterRfq): BlotterRfqReceivedAction {
  return {
    type: 'BLOTTER_RFQ_RECEIVED',
    rfq,
  };
}

export function createBlotterRfqsReceivedAction(rfqs: NeosBlotterRfq[]): BlotterRfqsReceivedAction {
  return {
    type: 'BLOTTER_RFQS_RECEIVED',
    rfqs,
  };
}

export function requestBlotterRfqs(
  from: string,
  to: string,
  workflows: Workflow[],
  underlyingIds: string[],
  counterpartIds: string[],
  statuses: string[],
  strategyTypes: string[],
  maturityDateFrom: string,
  maturityDateTo: string,
): RequestBlotterRfqsAction {
  return {
    type: 'REQUEST_BLOTTER_RFQS',
    from,
    to,
    workflows,
    underlyingIds,
    counterpartIds,
    statuses,
    strategyTypes,
    maturityDateFrom,
    maturityDateTo,
  };
}

export function requestSearchByBookingId(bookingId: string): RequestSearchByBookingIdAction {
  return {
    type: 'REQUEST_SEARCH_BY_BOOKING_ID',
    bookingId,
  };
}

export function requestCreateSecondaryFromXoneId(
  bookingId: string,
  eventType: EventType,
): RequestCreateSecondaryFromXoneIdAction {
  return {
    type: 'REQUEST_CREATE_SECONDARY_FROM_XONE_ID',
    bookingId,
    eventType,
  };
}

export function changeBlotterMultipleCriterias(
  typeaheadValues: MultipleTypeaheadValue[],
): ChangeBlotterMultipleCriteriasAction {
  return {
    type: 'CHANGE_BLOTTER_TYPEAHEAD',
    typeaheadValues,
  };
}

export function changeBlotterActiveMultipleCriterias(
  typeaheadValues: MultipleTypeaheadValue[],
): ChangeBlotterActiveMultipleCriteriasAction {
  return {
    type: 'CHANGE_BLOTTER_ACTIVE_TYPEAHEAD',
    typeaheadValues,
  };
}

export function changeBlotterFromDate(from: string): ChangeBlotterFromDateAction {
  return {
    type: 'CHANGE_BLOTTER_FROM_DATE',
    from,
  };
}

export function changeBlotterMaturityDateRange(
  maturityDateFrom: string,
  maturityDateTo: string,
): ChangeBlotterMaturityDateRangeAction {
  return {
    type: 'CHANGE_BLOTTER_MATURITY_DATE_RANGE',
    maturityDateFrom,
    maturityDateTo,
  };
}

export function changeBlotterRequestTime(time: string): ChangeBlotterRequestTimeAction {
  return {
    type: 'CHANGE_BLOTTER_REQUEST_TIME',
    time,
  };
}

export function changeBlotterToDate(to: string): ChangeBlotterToDateAction {
  return {
    type: 'CHANGE_BLOTTER_TO_DATE',
    to,
  };
}

export function changeBlotterWorkflows(workflows: Workflow[]): ChangeBlotterWorkflowsAction {
  return {
    type: 'CHANGE_BLOTTER_WORKFLOWS',
    workflows,
  };
}

export function updateBlotterRfq(rfq: NeosBlotterRfq): BlotterRfqUpdatedAction {
  return {
    type: 'BLOTTER_RFQ_UPDATED',
    rfq,
  };
}

export function updateBlotterRfqFilteredOutNotificationLastDate(): BlotterRfqNotificationReceivedAction {
  return {
    type: 'BLOTTER_RFQ_NOTIFICATION_RECEIVED_ACTION',
  };
}

export function resetBlotterRfqFilteredOutNotificationLastDate(): BlotterRfqNotificationResetAction {
  return {
    type: 'BLOTTER_RFQ_NOTIFICATION_RESET_ACTION',
  };
}

export function changeBlotterSubfilter(subfilter: SubfilterType): ChangeBlotterSubfilterAction {
  return {
    type: 'CHANGE_BLOTTER_SUBFILTER',
    subfilter,
  };
}

function createRequestBulkCancelRfqsAction(
  rfqIds: string[],
  reason: BulkCancelReason,
): RequestBulkCancelRfqsAction {
  return {
    type: 'REQUEST_BULK_CANCEL_RFQS_ACTION',
    rfqIds,
    reason,
  };
}
