import type { SgmeHttp } from '@/util/http/sgmeHttpBase';
import type { Observable } from 'rxjs';
import type { OnyxElectronicMediaUser } from './electronicMediaUserModel';
import type { ElectronicMediaUsersApi } from './electronicMediaUsersEpic';

export const makeElectronicMediaUsersApi = (http: SgmeHttp): ElectronicMediaUsersApi => ({
  getElectronicMediaUsers(counterpartId: number): Observable<OnyxElectronicMediaUser[]> {
    return http.get<OnyxElectronicMediaUser[]>({
      url: `api/referential/confirmations/users?counterpartId=${counterpartId}`,
    });
  },
});
