import { selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import { thunks } from '@/bootstrap/thunks';
import type { ElsClsFlowModeType } from '@/neos/business/rfq/strategy/leg/legOnyxModel';
import { FlowMode } from '@/neos/components/rfq/postNego/allocs/otcAllocations/secondaryAllocations/FlowMode';
import { useDispatch, useSelector } from 'react-redux';

export function ClsFlowMode({
  allocationId,
  rfqId,
  legId,
}: {
  allocationId: string;
  rfqId: string;
  legId: string;
}) {
  const dispatch = useDispatch();

  const currentAllocation = useSelector((state: AppState) =>
    selectors.getOtcAllocation(state, rfqId, legId, allocationId),
  );
  const flowMode = currentAllocation?.secondaryInfo?.flowMode;

  const onFlowModeChange = (value: ElsClsFlowModeType | undefined) => {
    dispatch(
      thunks.neos.createUpdateSecondaryInfoThunk(allocationId, rfqId, legId, {
        flowMode: value,
      }),
    );
  };

  return (
    <FlowMode
      legId={legId}
      value={flowMode}
      onFlowChange={onFlowModeChange}
      dataE2e="otc-alloc-cls-flow-mode"
    />
  );
}
