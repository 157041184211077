import { importExcelFileContent } from '@/util/excel/excel';
import { neosThunks } from '@/neos/business/thunks';
import { useRef } from 'react';
import { useDispatch } from 'react-redux';
import type { Els } from '@/neos/business/rfq/strategy/leg/product/productModel';

type ElsBuyAndSemmImportProps = {
  rfqId: string;
  product: Els;
};

export const ElsBuyAndSellImport = ({ product, rfqId }: ElsBuyAndSemmImportProps) => {
  const dispatch = useDispatch();

  function onImport(importedData: unknown[]) {
    dispatch(
      neosThunks.createImportElsBuyAndSellCompositionThunk(rfqId, product.uuid, importedData),
    );
  }

  const fileInputRef = useRef<HTMLInputElement>(null);

  return (
    <button
      onClick={() => {
        fileInputRef.current?.click();
      }}
      className="btn btn-discreet-primary"
    >
      Import <i className="icon icon-sm ms-2">file_upload</i>
      <input
        ref={fileInputRef}
        data-e2e="els-buy-and-sell-import"
        type="file"
        accept=".xlsx"
        className="d-none"
        onChange={e => importExcelFileContent(e, onImport)}
      />
    </button>
  );
};
