import { actionCreators } from '@/bootstrap/actions';
import { useAppSelector } from '@/bootstrap/hooks';
import { selectors } from '@/bootstrap/selectors';
import { thunks } from '@/bootstrap/thunks';
import {
  clientWays,
  type Counterpart,
  type EquityHedge,
  type EquityHedgeComponent,
  initialEquityHedge,
  LegRecomputeScope,
} from '@/neos/business/rfq/models';
import { UnderlyingInput } from '@/neos/components/rfq/strategies/strategy/common';
import { NeosBlurInput } from '@/neos/components/share/blurComponent/NeosBlurInput';
import { NumericInput } from '@/neos/components/share/numericInput';
import { useDispatch } from 'react-redux';
import { toUpperCase } from '@/util/string/stringUtils.ts';
import { NeosSelect } from '@/neos/components/share/NeosSelect/NeosSelect.tsx';
import { ElsFeaturesContext } from '@/neos/components/rfq/strategies/strategy/features/elsFeatures/ElsFeatures.tsx';
import { useContext } from 'react';
import {
  NeosSelectWithAutocomplete,
  type Option,
} from '@/neos/components/share/neosSelectWithAutocomplete/NeosSelectWithAutocomplete.tsx';

export type ElsBuyAndSellGridRowProps = {
  index: number;
  equityComponent: EquityHedgeComponent;
  isInternalPortfolioDisplayed: boolean;
};

export function ElsBuyAndSellGridRow({
  index,
  equityComponent,
  isInternalPortfolioDisplayed,
}: ElsBuyAndSellGridRowProps) {
  const dispatch = useDispatch();
  const { product, rfqId, strategyId } = useContext(ElsFeaturesContext);

  const underlyingTypes = useAppSelector(state =>
    selectors.getUnderlyingTypes(state, strategyId, selectors),
  );

  const underlyingId = equityComponent.underlyingId;
  const underlyingDetails = useAppSelector(state =>
    selectors.getUnderlyingInfo(state, underlyingId),
  );

  const { isElsEquityHedgeTypeInternal, equityType, equityTypeOptions } = useAppSelector(state =>
    selectors.getEquityHedgeTypeAndOptions(state, rfqId, equityComponent.equityType, selectors),
  );

  const availableClients = useAppSelector(state =>
    selectors.getAndFormatAvailableClients(state, rfqId, selectors),
  );

  const options: Option<Counterpart>[] = availableClients.map(counterpart => ({
    value: counterpart.id.toString(),
    label: counterpart.name,
    tag: counterpart,
  }));

  function onDelete() {
    const oldEquityHedge: EquityHedge = product.equityHedge ?? initialEquityHedge;
    dispatch(
      actionCreators.neos.productCrudActions.update(product.uuid, {
        equityHedge: {
          ...oldEquityHedge,
          equityHedgeComponents: oldEquityHedge.equityHedgeComponents.toSpliced(index, 1),
        },
      }),
    );
  }

  return (
    <>
      <button className="btn btn-icon btn-flat-primary" onClick={onDelete}>
        <i className="icon icon-md">delete_forever</i>
      </button>

      <UnderlyingInput
        autoFocus={underlyingId === undefined && underlyingDetails?.bloombergCode === undefined}
        componentId={`neos-els-buyAndSell-underlying-${product.legId}-${equityComponent.uuid}`}
        underlyingId={underlyingId}
        bloombergCode={underlyingDetails?.bloombergCode}
        underlyingTypes={underlyingTypes}
        positionFixed
        data-e2e="neos-els-BuyAndSell-underlying"
        onChange={event => {
          const newUnderlyingId = event?.value;
          dispatch(
            thunks.neos.createUpdateElsEquityHedgeComponentUnderlyingThunk(
              product,
              rfqId,
              newUnderlyingId,
              index,
            ),
          );
        }}
      />

      <NumericInput
        value={equityComponent.quantity}
        onBlur={value => {
          dispatch(
            thunks.neos.createUpdateElsEquityComponentThunk(product.uuid, index, 'quantity', value),
          );
          dispatch(
            actionCreators.neos.createRecomputeRfqRequestedAction(
              rfqId,
              LegRecomputeScope.CHANGE_EQUITY_HEDGE,
            ),
          );
        }}
        data-e2e="els-equityComponent-quantity"
      />

      <NumericInput
        data-e2e="els-equityComponent-spot"
        value={equityComponent.spot?.value}
        unit={equityComponent.spot?.unit}
        onBlur={value => {
          dispatch(
            thunks.neos.createUpdateElsEquityComponentThunk(product.uuid, index, 'spot', {
              ...equityComponent.spot,
              value,
            }),
          );
        }}
      />

      <NumericInput
        data-e2e="els-equityComponent-spotNet"
        value={equityComponent.spotNet?.value}
        unit={equityComponent.spotNet?.unit}
        onBlur={value => {
          dispatch(
            thunks.neos.createUpdateElsEquityComponentThunk(product.uuid, index, 'spotNet', {
              ...equityComponent.spotNet,
              value,
            }),
          );
          dispatch(
            actionCreators.neos.createRecomputeRfqRequestedAction(
              rfqId,
              LegRecomputeScope.CHANGE_EQUITY_HEDGE,
            ),
          );
        }}
      />

      <NumericInput
        readOnly
        data-e2e="els-equityComponent-nominal"
        value={equityComponent.nominal?.value}
        unit={equityComponent.nominal?.unit}
      />

      <NeosSelectWithAutocomplete
        data-e2e="els-equityComponent-Counterpart"
        value={equityComponent.counterparty?.id.toString()}
        onChange={selectedOption =>
          dispatch(
            thunks.neos.createUpdateElsEquityComponentThunk(
              product.uuid,
              index,
              'counterparty',
              selectedOption?.tag,
            ),
          )
        }
        addEmptyOption
        options={options}
      />

      <NeosBlurInput
        value={equityComponent.broker}
        onBlur={event => {
          dispatch(
            thunks.neos.createUpdateElsEquityComponentThunk(
              product.uuid,
              index,
              'broker',
              event.target.value,
            ),
          );
        }}
        data-e2e="els-equityComponent-broker"
      />

      <NeosBlurInput
        value={equityComponent.portfolio}
        transform={toUpperCase}
        onBlur={event => {
          dispatch(
            thunks.neos.createUpdateElsEquityComponentThunk(
              product.uuid,
              index,
              'portfolio',
              event.target.value,
            ),
          );
        }}
        data-e2e="els-equityComponent-portfolio"
      />

      {isInternalPortfolioDisplayed && (
        <NeosBlurInput
          value={equityComponent.internalPortfolio}
          transform={toUpperCase}
          data-e2e="els-equityComponent-internalPortfolio"
          onBlur={event => {
            dispatch(
              thunks.neos.createUpdateElsEquityComponentThunk(
                product.uuid,
                index,
                'internalPortfolio',
                event.target.value,
              ),
            );
          }}
        />
      )}

      {isElsEquityHedgeTypeInternal ? (
        <NeosSelect
          addEmptyOption
          value={equityType}
          options={equityTypeOptions}
          onChange={value => {
            dispatch(
              thunks.neos.createUpdateElsEquityComponentThunk(
                product.uuid,
                index,
                'equityType',
                value,
              ),
            );
          }}
          data-e2e="els-equityComponent-equityType"
        />
      ) : (
        <NeosSelect
          addEmptyOption
          value={equityType}
          options={equityTypeOptions}
          onChange={value => {
            dispatch(
              thunks.neos.createUpdateElsEquityComponentThunk(
                product.uuid,
                index,
                'equityType',
                value,
              ),
            );
          }}
          data-e2e="els-equityComponent-equityType"
        />
      )}

      <NeosSelect
        value={equityComponent.way}
        onChange={value => {
          dispatch(
            thunks.neos.createUpdateElsEquityComponentThunk(product.uuid, index, 'way', value),
          );
        }}
        addEmptyOption
        options={clientWays}
        data-e2e="els-equityComponent-way"
      />

      {/*<NeosBookingId*/}
      {/*  bookingId={equityComponent.bookingId}*/}
      {/*  onBookingIdChanged={bookingId =>*/}
      {/*    dispatch(*/}
      {/*      thunks.neos.createUpdateElsEquityComponentThunk(*/}
      {/*        product.uuid,*/}
      {/*        index,*/}
      {/*        'bookingId',*/}
      {/*        bookingId,*/}
      {/*      ),*/}
      {/*    )*/}
      {/*  }*/}
      {/*  dataE2e="els-equityComponent-portfolio"*/}
      {/*/>*/}
    </>
  );
}
