import type { Selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import { mapSourceToBadgeText } from '@/neos/business/rfq/rfqOnyxModel';
import type { InfoBadgeValue } from '../TabTitleBadge';
import type { TabTitleStateProps } from './TabTitle';

export function getRfqTabTitleModel(
  appState: AppState,
  rfqId: string,
  selectors: Selectors,
): TabTitleStateProps {
  const { source } = selectors.getRfqData(appState, rfqId);
  const isLoading = selectors.isRfqLoading(appState.ui, rfqId);
  const active = selectors.getSelectedTab(appState.ui.tabManagement) === rfqId;
  const isAlertIconDisplayed =
    !!selectors.isLastRfqSavingDone(appState, rfqId) &&
    selectors.isThereAPendingNotification(appState, rfqId);
  const counterpart = selectors.getSalesCounterpart(appState, rfqId, selectors);
  const masterStrategyId = selectors.getRfqMasterStrategy(appState, rfqId).uuid;
  const underlyingLabel = selectors.getStrategyMasterUnderlyingLabel(
    appState,
    masterStrategyId,
    selectors,
  );
  const counterpartName = counterpart?.name;
  const common: Omit<TabTitleStateProps, 'isBadgeDisplayed' | 'infoBadgeValue'> = {
    active,
    isLoading,
    isAlertIconDisplayed,
    label: [
      counterpartName
        ? {
            text: counterpartName.substring(0, 9),
            className: '',
          }
        : {
            text: 'No Ctpt',
            className: 'fst-italic fw-lighter',
          },
      underlyingLabel
        ? {
            text: underlyingLabel,
            className: '',
          }
        : {
            text: 'No Udl',
            className: 'fst-italic fw-lighter',
          },
    ],
    isPinned: false,
    tabsCount: selectors.getTabs(appState.ui.tabManagement).length,
  };

  const isFeatureToggleEnabled = selectors.isSecondaryFeatureToggleEnabled(appState);
  const isSecondaryRfq = selectors.isSecondaryRfq(appState, rfqId);

  if (source !== 'VOICE') {
    const infoBadgeValue: InfoBadgeValue = {
      badgeText: mapSourceToBadgeText(source),
      badgeColorClassName: 'bg-success',
    };
    return {
      ...common,
      isBadgeDisplayed: true,
      infoBadgeValue,
    };
  }

  return {
    ...common,
    isBadgeDisplayed: false,
    infoBadgeValue: undefined,
    isFeatureToggleEnabled,
    isSecondaryRfq,
  };
}
