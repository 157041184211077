import type { Thunk } from '@/bootstrap/thunks';
import type { ActionContainer } from '@/util/actionContainer';
import { getToday } from '@/util/date/dateFormatHelper';

interface BlotterRequestParameters {
  from?: string;
  to?: string;
}

export function createRequestBlotterThunk({
  from = undefined,
  to = undefined,
}: BlotterRequestParameters = {}): Thunk {
  return function requestBlotterThunk(
    dispatch,
    getState,
    {
      actionCreators: {
        analytics: { createLogAnalyticsAction },
        neos: { requestBlotterRfqs, changeBlotterFromDate, changeBlotterToDate },
      },
      selectors: {
        getBlotterWorkflows,
        getBlotterTypeaheadValue,
        getBlotterUiFrom,
        getBlotterUiTo,
        getBlotterMaturityDateFromValue,
        getBlotterMaturityDateToValue,
      },
    },
  ) {
    const state = getState();

    const workflows = getBlotterWorkflows(state.blotter);
    const typeaheadValueFromBlotter = getBlotterTypeaheadValue(state.blotter);
    const maturityDateFrom = getBlotterMaturityDateFromValue(state.blotter);
    const maturityDateTo = getBlotterMaturityDateToValue(state.blotter);

    const underlyings = typeaheadValueFromBlotter.filter(val => val.origin === 'UNDERLYING');
    const counterparts = typeaheadValueFromBlotter.filter(val => val.origin === 'COUNTERPART');
    const statuses = typeaheadValueFromBlotter.filter(val => val.origin === 'STATUS');
    const strategyTypes = typeaheadValueFromBlotter.filter(val => val.origin === 'STRATEGY_TYPE');

    const actions: ActionContainer[] = [];
    const fromUi = getBlotterUiFrom(state.blotter);
    const toUi = getBlotterUiTo(state.blotter);

    const newFrom: string = from && fromUi !== from ? from : fromUi;
    const newTo: string = to && toUi !== to ? to : toUi;

    if (fromUi !== newFrom) {
      actions.push(changeBlotterFromDate(newFrom));
    }
    if (toUi !== newTo) {
      actions.push(changeBlotterToDate(newTo));
    }

    actions.push(
      ...[
        createLogAnalyticsAction(
          'NEOS BLOTTER',
          `Search button hit${from === getToday() ? ' for today' : ''}${
            underlyings.length > 0 ? ' with underlyings' : ''
          }`,
        ),
        requestBlotterRfqs(
          newFrom,
          newTo,
          workflows,
          underlyings.map(underlying => underlying.value),
          counterparts.map(counterpart => counterpart.value),
          statuses.map(status => status.value),
          strategyTypes.map(status => status.value),
          maturityDateFrom,
          maturityDateTo,
        ),
      ],
    );
    dispatch(actions);
  };
}
