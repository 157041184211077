import type { Thunk } from '@/bootstrap/thunks.ts';
import type { ValidImportedEquityHedgeCompositionData } from '@/neos/business/rfq/strategy/leg/product/els/thunks/createImportElsBuyAndSellCompositionThunk.ts';
import type { Underlying } from '@/neos/business/rfq/strategy/leg/product/productModel.ts';

export function createHandleEquityHedgeCompositionUnderlyingsReceivedThunk(
  rfqId: string,
  productId: string,
  validatedEquityHedgeCompositionData: ValidImportedEquityHedgeCompositionData,
  receivedUnderlyingIds: Underlying[],
): Thunk {
  return function handleEquityHedgeCompositionUnderlyingsReceivedThunk(
    dispatch,
    getState,
    { selectors, actionCreators, thunks },
  ) {
    const state = getState();

    if (!selectors.isRfqEls(state, rfqId, selectors)) {
      return;
    }

    const retrievedBloombergCodes = receivedUnderlyingIds.map(element => element.bloombergCode);

    const missingBloombergCodes = validatedEquityHedgeCompositionData
      .filter(line => {
        const bloombergCode = line.bloombergCode;
        return !retrievedBloombergCodes.includes(bloombergCode);
      })
      .map(line => line.bloombergCode);

    if (missingBloombergCodes.length === 0) {
      const underlyingIds = receivedUnderlyingIds.map(underlying => underlying.id);
      const underlyingsInState = selectors.getUnderlyingsInfo(state, underlyingIds);
      const underlyingIdsNotInState = underlyingIds.filter(
        underlyingId =>
          !underlyingsInState.find(underlyingInState => underlyingInState.id === underlyingId),
      );

      dispatch(
        thunks.neos.createRequestMissingUnderlyingsThunk(
          [rfqId],
          {
            success: {
              dispatchables: [
                thunks.neos.createUpdateProductAndReferenceWithImportedEquityHedgeCompositionThunk(
                  rfqId,
                  productId,
                  receivedUnderlyingIds,
                  validatedEquityHedgeCompositionData,
                ),
              ],
            },
          },
          underlyingIdsNotInState,
        ),
      );
    } else {
      const errorMessage = `Error when retrieving underlyings, some imported underlyings cannot be retrieved or does not exist: ${missingBloombergCodes.join(', ')}`;
      dispatch(actionCreators.common.createLogAction(errorMessage, undefined, true));
      dispatch(
        thunks.createErrorToasterThunk(
          {
            message: errorMessage,
          },
          undefined,
        ),
      );
    }
  };
}
