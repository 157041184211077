import { importFromExcelClipboardData } from '@/util/excel/excel';
import { neosThunks } from '@/neos/business/thunks';
import { useDispatch } from 'react-redux';
import type { Els } from '@/neos/business/rfq/strategy/leg/product/productModel';
import { thunks } from '@/bootstrap/thunks.ts';

type ElsBuyAndSellClipboardPasteProps = {
  rfqId: string;
  product: Els;
};

export const ElsBuyAndSellClipboardPaste = ({
  product,
  rfqId,
}: ElsBuyAndSellClipboardPasteProps) => {
  const dispatch = useDispatch();

  function handleError(error: string) {
    dispatch(thunks.createErrorToasterThunk({ message: error }, undefined));
  }

  function handleClipboardData(importedData: unknown[]) {
    dispatch(
      neosThunks.createImportElsBuyAndSellCompositionThunk(rfqId, product.uuid, importedData),
    );
  }

  return (
    <button
      onClick={() => {
        importFromExcelClipboardData(handleClipboardData, handleError);
      }}
      className="btn btn-icon btn-discreet-primary"
    >
      <i className="icon">content_paste</i>
    </button>
  );
};
