import type { Selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import type { PreConfirmationStep } from './PreConfirmationStepModel';

export function mapToOnyxPreConfirmationSteps(
  state: AppState,
  rfqId: string,
  selectors: Selectors,
): PreConfirmationStep[] {
  const preConfirmationSteps = selectors.preConfirmationStepSelectors.selectObjects(
    state.preConfirmationStep,
    { rfqId },
  );
  return preConfirmationSteps;
}
