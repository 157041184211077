import type { Thunk } from '@/bootstrap/thunks.ts';
import { isElsProduct } from '@/neos/business/rfq/strategy/leg/product/productModel.ts';
import { RawImportedDataSchema } from '@/util/excel/excel.ts';
import { fromError } from 'zod-validation-error';
import { camelCase } from 'lodash';
import { elsBuyAndSellImportApproximateImportedKeys } from '@/neos/business/rfq/strategy/leg/product/els/ElsBuyAndSellImportApproximateImportedKeys.ts';
import type { ExcelNumberFormat } from '@/neos/business/ui/userPreferences/userPreferencesUiModel.ts';
import { z } from 'zod';
import { transformToNumberIfDefined } from '@/neos/business/services/importUtils.ts';
import type { WayValues } from '@/neos/business/rfq/rfqData/rfqDataModel.ts';
import { clientWays } from '@/neos/business/rfq/rfqData/rfqDataModel.ts';
import {
  internalEquityHedgeTypes,
  otherEquityHedgeTypes,
} from '@/neos/business/rfq/strategy/leg/product/elsProductOnyxModel.ts';
import { unionOfLiterals } from '@/util/zod/zod-util.ts';

export type ValidImportedEquityHedgeCompositionData = z.output<
  ReturnType<typeof getImportedDataSchema>
>;

export function createImportElsBuyAndSellCompositionThunk(
  rfqId: string,
  productId: string,
  rawImportedData: unknown[],
): Thunk {
  return function importElsBuyAndSellCompositionThunk(
    dispatch,
    getState,
    { actionCreators, thunks, selectors },
  ) {
    const state = getState();
    const excelNumberFormat = selectors.selectExcelNumberFormat(state.ui.userPreferences);
    const product = selectors.getProduct(state, productId);
    const isRfqInternal = selectors.isRfqInternalEls(state, rfqId, selectors);

    if (!isElsProduct(product)) {
      return;
    }
    const rawParsingResult = RawImportedDataSchema.safeParse(rawImportedData);

    if (!rawParsingResult.success) {
      const zodError = fromError(rawParsingResult.error, {
        prefix: 'Error trying to parse imported lines',
      }).toString();

      dispatch(actionCreators.common.createLogAction(zodError, undefined, true));
      dispatch(
        thunks.createErrorToasterThunk(
          {
            message: zodError,
          },
          undefined,
        ),
      );
      return;
    }

    const importedDataWithSanitizedKeys: Record<string, string>[] = rawParsingResult.data.map(
      line =>
        Object.fromEntries(
          Object.entries(line).map(([key, value]) => {
            return [camelCase(key), value];
          }),
        ),
    );

    const approximatedImportedData = elsBuyAndSellImportApproximateImportedKeys(
      importedDataWithSanitizedKeys,
    );

    const parsingResult = getImportedDataSchema(excelNumberFormat, isRfqInternal).safeParse(
      approximatedImportedData,
    );

    if (!parsingResult.success) {
      const zodError = fromError(parsingResult.error, {
        prefix: 'Error trying to parse imported Buy And Sell composition',
      }).toString();

      dispatch(actionCreators.common.createLogAction(zodError, undefined, true));
      dispatch(
        thunks.createErrorToasterThunk(
          {
            message: zodError,
          },
          undefined,
        ),
      );
      return;
    }

    const validImportedData = parsingResult.data as ValidImportedEquityHedgeCompositionData;
    const importedBloombergCodes = validImportedData.map(line => line.bloombergCode);

    const areSomeDuplicatedBloombergCodes = importedBloombergCodes.some((item, index) => {
      return importedBloombergCodes.indexOf(item) !== index;
    });

    if (areSomeDuplicatedBloombergCodes) {
      const message =
        'Error trying to import Buy And Sell composition: Some Bloomberg codes are duplicated.';
      dispatch(actionCreators.common.createLogAction(message, undefined, true));
      dispatch(
        thunks.createErrorToasterThunk(
          {
            message,
          },
          undefined,
        ),
      );
      return;
    }

    dispatch(
      actionCreators.neos.createEquityHedgeCompositionImportUnderlyingIdsRequestedAction(
        rfqId,
        productId,
        validImportedData,
      ),
    );
  };
}

const getImportedDataSchema = (excelNumberFormat: ExcelNumberFormat, isRfqInternal: boolean) => {
  const toNumber = (value: string | undefined, ctx: z.RefinementCtx) => {
    return transformToNumberIfDefined(value, ctx, excelNumberFormat);
  };

  return z.array(
    z.object({
      bloombergCode: z.string().min(1),
      quantity: z.string().min(1).transform(toNumber),
      spot: z.string().optional().transform(toNumber),
      spotUnit: z.string().optional(),
      spotNet: z.string().optional().transform(toNumber),
      spotNetUnit: z.string().optional(),
      execFeesIn: z.string().optional().transform(toNumber),
      counterpart: z.string().optional(),
      broker: z.string().optional(),
      portfolio: z.string().optional(),
      ...(isRfqInternal && { internalPortfolio: z.string().optional() }),
      type: equityTypeSchema.optional(),
      way: clientWaySchema.optional(),
    }),
  );
};

const clientWaysValues = Object.values(clientWays) as ReadonlyArray<WayValues>;
export const clientWaySchema = unionOfLiterals(clientWaysValues);

export const equityTypeSchema = z.union([
  z.literal(internalEquityHedgeTypes.INTERNAL),
  z.literal(otherEquityHedgeTypes.BACK_TO_BACK),
  z.literal(otherEquityHedgeTypes.WITH_BROKER),
]);
