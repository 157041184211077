import type {
  CommissionType,
  DateWithOffset,
  DeltaType,
  DeterminationMethod,
  IATypeValuesType,
  PriceUnitType,
  StrategyScope,
  StrategyType,
  SwapsWireSgTraderAccountType,
} from '../../../../neos/business/neosModel';
import type {
  ActivityType,
  BidAsk,
  BookingId,
  OnyxCounterparty,
  OnyxFairPrice,
  OnyxLeg,
  OnyxNegotiatedSize,
  OnyxProductUnderlying,
  OnyxQuote,
  OnyxStrategyReference,
  PriceWithUnit,
} from '../../../../neos/business/neosOnyxModel';

export * from './leg/legOnyxModel';

export interface OnyxPeople {
  id: string;
  physicalLocation?: string;
}

export interface OnyxGroup {
  id?: number;
  value: string;
}

export interface TraderOnyxGroup extends OnyxGroup {
  id: number | undefined;
}

interface OnyxTraderDiffusion {
  trader?: OnyxPeople;
  traderGroup?: TraderOnyxGroup;
  etdDeskCity?: string;
  executor?: string;
  tradingBusiness?: unknown; // value simply taken and sent back to onyx whitout user interaction
}

interface OnyxAbstractConfirmationMedia {
  eligibilityReason: string | undefined;
}

export interface OnyxThirdPartyTrader {
  id: string;
  type: 'USER' | 'GROUP';
}

export interface OnyxMarkitConfirmationMedia extends OnyxAbstractConfirmationMedia {
  discriminator: 'MARKITWIRE';
  mode: 'AUTO' | 'MANUAL';
  counterpartyTrader: OnyxThirdPartyTrader | undefined;
  markitWireReferenceId: BookingId | undefined;
}

export type PaperForcedReason =
  | 'SG_BOOKING_SPECIFICS'
  | 'SG_OTHER_REASON'
  | 'CTP_NOT_READY_ON_MW_ELS_FORWARD'
  | 'CTP_NOT_READY_ON_MW_EMERGENCY_MCA'
  | 'CTP_OTHER_REASON';

export interface OnyxClearingInfo {
  clearerAccount: string | undefined;
}

export interface OnyxCommissionInfo {
  commission: number | undefined;
  commissionType: CommissionType | undefined;
}

export interface OnyxPaperConfirmationMedia extends OnyxAbstractConfirmationMedia {
  discriminator: 'PAPER';
  form: 'UNKNOWN' | 'LONG_FORM' | 'SHORT_FORM' | undefined;
  forcedReason: PaperForcedReason | undefined;
}

export type OnyxAllocConfirmationMedia = OnyxMarkitConfirmationMedia | OnyxPaperConfirmationMedia;

export type OnyxPreAllocConfirmationMedia = OnyxAllocConfirmationMedia;

interface AbstractOnyxOtcPreAlloc {
  counterparty: OnyxCounterparty | undefined;
}

export interface OnyxIndependantAmount {
  nominal: IANominal;
  valueDate?: string;
}

export interface SecondaryOnyxIndependantAmount {
  nominal: PriceWithUnit;
  valueDate?: string;
}

export interface IANominal {
  unit?: string;
  value?: number;
  type?: IATypeValuesType;
}
export interface OnyxOtcPreAlloc extends AbstractOnyxOtcPreAlloc {
  discriminator: 'OTC';
  confirmationMedia: OnyxPreAllocConfirmationMedia | undefined;
  ratio: number | undefined;
  independentAmount: OnyxIndependantAmount | undefined;
  markitWireEligible: boolean | undefined;
  mcaEligible: boolean | undefined;
}

export interface OnyxListedPreAlloc extends AbstractOnyxOtcPreAlloc {
  discriminator: 'LISTED';
  broker: OnyxCounterparty | undefined;
  clearingInfo: OnyxClearingInfo | undefined;
  commissionInfo: OnyxCommissionInfo | undefined;
  ratio: number | undefined;
}

export type OnyxPreAlloc = OnyxOtcPreAlloc | OnyxListedPreAlloc;

export interface StrategyTypeInfo {
  strategyType: StrategyType;
  top: number | undefined;
  underlyingTop: OnyxProductUnderlying | undefined;
}

export type OrderType = 'MARKET' | 'LIMIT' | 'MARKET_ON_CLOSE';

export interface OnyxStrategy {
  discriminator: StrategyScope;
  uuid: string;
  activityType?: ActivityType;
  comment?: string;
  bookingId?: BookingId;
  legs: OnyxLeg[];
  strategyTypeInfo?: StrategyTypeInfo;
  strategyType: StrategyType;
  weight?: number;
  traderDiffusion?: OnyxTraderDiffusion;
  deltaType?: DeltaType;
  determinationMethod?: DeterminationMethod;
  initialPriceDetermination?: DeterminationMethod;
  valueDate: DateWithOffset | undefined;
  references: OnyxStrategyReference[];
  master: boolean;
  quote?: OnyxQuote;
  fairPrice?: OnyxFairPrice;
  preAllocs: OnyxPreAlloc[] | undefined;
  handlingInstruction?: string;
  type?: OrderType;
  timeInForce?: string;
  limitPrice?: PriceWithUnit;
  compoNegotiatedSize: OnyxNegotiatedSize | undefined;
  screenPrice?: BidAsk;
  bookingInfo?: StrategyBookingInfo;
  priceUnitType: PriceUnitType;
  crossPartially?: boolean;
  broker?: string;
  executionType?: ExecutionType;
  swapsWireSgTrader: OnyxSwapsWireSgTrader | undefined;
}
export interface StrategyBookingInfo {
  portfolioId: string | undefined;
  operatingCenterId: string | undefined;
  mirrorPortfolioId: string | undefined;
}

export type ExecutionType = 'CLOSE' | 'INTRADAY' | 'RISK' | undefined;

export const availableExecutionTypes: {
  value: Exclude<ExecutionType, undefined>;
  label: string;
}[] = [
  { value: 'CLOSE', label: 'On Close' },
  { value: 'INTRADAY', label: 'Intraday' },
  { value: 'RISK', label: 'Risk' },
];

export type OnyxSwapsWireSgTrader = {
  swapsWireAccount: string | undefined;
  swapsWireAccountType: SwapsWireSgTraderAccountType | undefined;
};
