import { z } from 'zod';

export type TypeToZod<T> = Required<{
  [K in keyof T]: T[K] extends string | number | boolean | null | undefined
    ? undefined extends T[K]
      ? z.ZodOptional<z.ZodType<Exclude<T[K], undefined>>>
      : z.ZodType<T[K]>
    : z.ZodObject<TypeToZod<T[K]>>;
}>;

export const createZodObject = <T>(obj: TypeToZod<T>) => {
  return z.object(obj);
};

/**
 * Allows to convert a const array of strings to a zod schema of those specific strings only.
 * @param constants const array of strings
 */
export function unionOfLiterals<T extends string | number>(constants: readonly T[]) {
  const literals = constants.map(x => z.literal(x)) as unknown as readonly [
    z.ZodLiteral<T>,
    z.ZodLiteral<T>,
    ...z.ZodLiteral<T>[],
  ];
  return z.union(literals);
}
