import { NeosSelectWithAutocomplete } from '@/neos/components/share/neosSelectWithAutocomplete/NeosSelectWithAutocomplete';
import { CounterpartPopover } from '../../share/counterpartPopover';
import { ErrorHighlight } from '../../share/errorHighlight';
import { If } from '../../share/if/if';
import styles from './ActorsSetup.module.scss';
import { SalesInitLocation } from './salesInitLocation/SalesInitLocation.container';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '@/bootstrap/hooks.ts';
import { getActorSetupModel } from '@/neos/components/rfq/actorsSetup/getActorSetupModel.ts';
import { selectors } from '@/bootstrap/selectors.ts';
import { neosThunks } from '@/neos/business/thunks';
import { thunks } from '@/bootstrap/thunks.ts';
import { createLogAnalyticsAction } from '@/bootstrap/middlewares/analytics';

export interface ActorsSetupProps {
  rfqId: string;
}

export const ActorsSetup = ({ rfqId }: ActorsSetupProps) => {
  const dispatch = useDispatch();

  const model = useAppSelector(state => getActorSetupModel(state, rfqId, selectors));

  const onSalesValoChanged = (salesInitId: string, salesValoId: string | undefined) => {
    dispatch(neosThunks.createChangeSalesValoThunk(rfqId, salesInitId, salesValoId));
  };

  const onCounterpartChanged = (counterpartId: number | undefined) => {
    if (counterpartId) {
      dispatch(neosThunks.createChangeSalesCounterpartyThunk(rfqId, counterpartId));
      dispatch(neosThunks.createRequestNeosPredealCheckRefreshThunk(rfqId));
    }
  };

  const getSelectedCounterpartName = () => {
    return model.availableClients.find(client => client.id.toString() === model.selectedClient)
      ?.name;
  };

  const copyCounterpartNameToClipboard = () => {
    const selectedCounterpartName = getSelectedCounterpartName();
    if (selectedCounterpartName) {
      dispatch(thunks.createCopyToClipboardThunk(selectedCounterpartName, 'Client name copied!'));
    }
    dispatch(createLogAnalyticsAction('NEOS RFQ', 'Select client - copy name'));
  };

  return (
    <div className={`${styles['actorsSetup']}`}>
      <div>
        <label>{model.userInitFieldName}</label>
        <span>{model.salesInitName}</span>
      </div>
      <SalesInitLocation rfqId={rfqId} />
      <If condition={!(model.isElsIdb || model.isInternalEls)}>
        <div>
          <label>Sales Valo</label>
          <NeosSelectWithAutocomplete
            data-e2e="neos-rfq-actors-setup-available-sales-valos"
            isReadOnly={model.isReadonlyRfq || model.availableSalesValos.length === 1}
            value={model.selectedSalesValo}
            onChange={selected => {
              onSalesValoChanged(model.salesInitId, selected?.value || undefined);
            }}
            addEmptyOption
            options={model.availableSalesValos.map(({ id, fullName }) => ({
              value: id,
              label: fullName,
            }))}
          />
        </div>
      </If>

      <If condition={!model.isInternalEls}>
        <div>
          <label>Client</label>
          <CounterpartPopover counterpart={model.counterpartPopoverInfo}>
            {/* span to prevent not forwarded ref error by ErrorHighlight in CounterpartPopover, see https://github.com/react-bootstrap/react-bootstrap/issues/2208#issuecomment-301737531 */}
            <span className="w-100">
              <ErrorHighlight errorField={'salesCounterparty'} related={{ rfqId }}>
                {/* span to prevent not forwarded ref error by NeosSelect in ErrorHighlight, see https://github.com/react-bootstrap/react-bootstrap/issues/2208#issuecomment-301737531 */}
                <span className="w-100">
                  <NeosSelectWithAutocomplete
                    isLabelBold
                    data-e2e="neos-rfq-actors-setup-available-clients"
                    isReadOnly={model.isReadonlyRfq}
                    value={model.selectedClient}
                    onChange={selected => {
                      onCounterpartChanged(
                        selected?.value ? parseInt(selected?.value, 10) : undefined,
                      );
                    }}
                    addEmptyOption
                    isDisabled={model.isDisabled}
                    options={model.availableClients.map(({ id, name, eliotCode, mnemo }) => ({
                      value: id.toString(),
                      label: name,
                      data: [eliotCode, mnemo],
                    }))}
                    classes={model.selectedClientKycTextColor}
                    onCopyIconPressed={copyCounterpartNameToClipboard}
                  />
                </span>
              </ErrorHighlight>
            </span>
          </CounterpartPopover>
        </div>
      </If>
    </div>
  );
};
