import type { Selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import type { StrategyTypeWithLabel } from '@/neos/business/referenceData/referenceDataSelectors';
import { sortBy } from 'lodash';
import {
  type StrategyScope,
  type StrategyType,
} from '../../../../../../../neos/business/neosModel';
import { getIsStrategyDefinitionFieldEditable } from '../utils/fieldsEnablingConditions';

export interface StrategyTypeCellModel {
  selectedStrategyType: StrategyType | undefined;
  availableStrategyTypes: StrategyTypeWithLabel[];
  disabled: boolean;
}

export function getStrategyTypeCellModel(
  appState: AppState,
  strategyId: string,
  rfqId: string,
  selectors: Selectors,
): StrategyTypeCellModel {
  const { strategyType: selectedStrategyType, scope } = selectors.getStrategyData(
    appState,
    strategyId,
  );
  const availableStrategyTypes = getAndFormatAvailableStrategyTypes(
    appState,
    rfqId,
    scope,
    selectedStrategyType,
    selectors,
  );

  const isTrader = selectors.isTrader(appState);
  const isOtcAndAmendWorkflowStatus = selectors.isOtcStrategyAndAmendWorkflowStatus(
    appState,
    rfqId,
    strategyId,
    selectors,
  );

  const disabled =
    !getIsStrategyDefinitionFieldEditable(appState, rfqId, strategyId, selectors, [
      'RFQ_SCOPE',
      'RFQ_EDITABLE',
    ]) ||
    isTrader ||
    isOtcAndAmendWorkflowStatus;

  return {
    selectedStrategyType,
    availableStrategyTypes,
    disabled,
  };
}

function getAndFormatAvailableStrategyTypes(
  state: AppState,
  rfqId: string,
  scope: StrategyScope,
  selectedStrategyType: string,
  selectors: Selectors,
): StrategyTypeWithLabel[] {
  const { viewableStrategies, readOnlyStrategies, editableStrategies, standaloneStrategies } =
    selectors.getStrategyConfiguration(state.referenceData);

  if (viewableStrategies.includes(selectedStrategyType)) {
    const { id, name, alias } = selectors.getStrategyDefinition(
      state.referenceData,
      selectedStrategyType,
    );
    return [
      {
        id,
        name,
        alias,
      },
    ];
  }

  const strategiesDefinitions = selectors.getStrategyDefinitions(state.referenceData, scope);

  let strategyDefinitionIds: StrategyTypeWithLabel[] = strategiesDefinitions
    .filter(strategy => [...editableStrategies, ...readOnlyStrategies].includes(strategy.id))
    .map(({ id, name, alias }) => ({ id, name, alias }));

  const { strategyIds } = selectors.getRfqData(state, rfqId);

  if (strategyIds.length > 1) {
    strategyDefinitionIds = strategyDefinitionIds.filter(
      strategy => !standaloneStrategies.includes(strategy.id),
    );
  }

  return sortBy(strategyDefinitionIds, strategyDefinition => strategyDefinition.name);
}
