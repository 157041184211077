import type { AppState } from '@/bootstrap/state';
import { getDifferencesExceptForIrrelevantFields } from '@/util/comparison/getDifferences';
import type { OnyxRfq } from '../neosOnyxModel';
import type { NotificationRfq } from './nestedRfqsModel';

export const nestedRfqsSelectors = {
  isLastRfqSavingDone,
  isThereAPendingNotification,
  getLastSavedRfqStatusComparedToCurrentOne,
  getPendingNotificationRfq,
  getLastSavedRfqVersion,
  isRfqSavingDone,
};

function isLastRfqSavingDone(state: AppState, rfqId: string): boolean | null {
  return state.nestedRfqs[rfqId] && state.nestedRfqs[rfqId].isSavingDone;
}

function isThereAPendingNotification(state: AppState, rfqId: string): boolean {
  return state.nestedRfqs[rfqId] && state.nestedRfqs[rfqId].notificationRfq.isThereNotificationRfq;
}

export type NestedRfqStatus = 'PENDING' | 'SAME_AS_CURRENT' | 'DIFFERENT_FROM_CURRENT';

function getLastSavedRfqStatusComparedToCurrentOne(
  state: AppState,
  rfqId: string,
  currentOnyxRfq: OnyxRfq,
): NestedRfqStatus {
  const { isSavingDone, lastSavedRfq } = state.nestedRfqs[rfqId];
  if (!isSavingDone) {
    return 'PENDING';
  }
  const differences = getDifferencesExceptForIrrelevantFields(currentOnyxRfq, lastSavedRfq);
  return differences.length ? 'DIFFERENT_FROM_CURRENT' : 'SAME_AS_CURRENT';
}

function isRfqSavingDone(state: AppState, rfqId: string): boolean | null {
  return state.nestedRfqs[rfqId].isSavingDone;
}

function getPendingNotificationRfq(state: AppState, rfqId: string): OnyxRfq | undefined {
  const notificationRfq: NotificationRfq | undefined = state.nestedRfqs[rfqId]?.notificationRfq;
  return notificationRfq?.isThereNotificationRfq ? notificationRfq.lastNotificationRfq : undefined;
}

function getLastSavedRfqVersion(state: AppState, rfqId: string): number | undefined {
  return state.nestedRfqs[rfqId]?.lastSavedRfq?.version;
}
