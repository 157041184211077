import type { Selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import { isDefined } from '@/util/undefinedAndNull/isDefined';
import { uniq } from 'lodash';
import type { OtcAllocation } from '../../../../../../neos/business/neosModel';
import type { Counterpart, OtcAllocationKey } from '../../../../../../neos/business/rfq/models';
import { otcAllocationDomain } from './otcAllocationDomain';
import { getRfqUpdateJustificationLabel } from './updateJustificationSelector';

export function getOtcAllocationsByLegId(state: AppState, legId: string): OtcAllocation[] {
  return otcAllocationDomain.selectors.selectObjects(state.otcAllocationState, { legId });
}

export function getOtcAllocationsByRfqId(state: AppState, rfqId: string): OtcAllocation[] {
  return otcAllocationDomain.selectors.selectObjects(state.otcAllocationState, { rfqId });
}

function getOtcAllocation(
  state: AppState,
  rfqId: string,
  legId: string,
  allocationId: string,
): OtcAllocation | undefined {
  return otcAllocationDomain.selectors.find(state.otcAllocationState, {
    rfqId,
    legId,
    allocationId,
  });
}

/**
 * Get the allocation corresponding to it's allocationId. The allocationId is unique.
 * @param state
 * @param allocationId
 * @returns If existing, returns the allocation found for the given allocationId, else returns undefined
 */
function getOtcAllocationByAllocId(
  state: AppState,
  allocationId: string,
): OtcAllocation | undefined {
  const otcAllocations = otcAllocationDomain.selectors.selectObjects(state.otcAllocationState, {
    allocationId,
  });

  return otcAllocations.length ? otcAllocations[0] : undefined;
}

export function getOtcAllocationsCounterparts(
  state: AppState,
  rfqId: string,
  selectors: Selectors,
): Counterpart[] {
  const counterparts = selectors.getCounterparts(state, rfqId);
  return getOtcAllocationsByRfqId(state, rfqId)
    .map(alloc => counterparts?.find(c => alloc.counterpartId === c.id))
    .filter(isDefined)
    .filter((alloc, index, array) => array.indexOf(alloc) === index);
}

function getAllocationValueDate(allocationKey: OtcAllocationKey, state: AppState) {
  return getOtcAllocation(
    state,
    allocationKey.rfqId,
    allocationKey.legId,
    allocationKey.allocationId,
  )?.independantAmountValueDate;
}

export function getOtcAllocationsNovationRemainingTransferees(
  state: AppState,
  rfqId: string,
  selectors: Selectors,
): number[] {
  const allocations = selectors.getOtcAllocationsByRfqId(state, rfqId);
  const allocTransfereeIds = allocations.map(alloc => alloc.secondaryInfo?.transferee?.id);
  return uniq(allocTransfereeIds.filter(isDefined));
}

export function shouldDisplayOtcAllocationsCashflows(
  state: AppState,
  rfqId: string,
  selectors: Selectors,
) {
  const { uuid } = selectors.getRfqMasterStrategy(state, rfqId);
  const isClsStrategy = selectors.isClsStrategy(state, uuid, selectors);
  const isSecondaryRfq = selectors.isSecondaryStrategy(state, uuid, selectors);
  return isClsStrategy && isSecondaryRfq;
}

export const otcAllocationSelectors = {
  getOtcAllocationsByLegId,
  getOtcAllocationsByRfqId,
  getOtcAllocationsCounterparts,
  getOtcAllocation,
  getAllocationValueDate,
  getOtcAllocationByAllocId,
  getOtcAllocationsNovationRemainingTransferees,
  getRfqUpdateJustificationLabel,
  shouldDisplayOtcAllocationsCashflows,
};
