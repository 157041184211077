import { useAppSelector } from '@/bootstrap/hooks';
import { createLogAnalyticsAction } from '@/bootstrap/middlewares/analytics';
import type { ExtendedDispatch } from '@/bootstrap/thunks';
import { neosActionCreators } from '@/neos/business/neosActionCreators';
import { ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import type { ExcelNumberFormat } from '@/neos/business/ui/userPreferences/userPreferencesUiModel.ts';

export function ExcelNumberFormatPreferences() {
  const dispatch = useDispatch<ExtendedDispatch>();

  const changeExcelNumberFormat = (excelNumberFormat: ExcelNumberFormat) => {
    dispatch(
      createLogAnalyticsAction('NEOS USER PREFERENCES', 'Change excel number format'),
      neosActionCreators.createExcelNumberFormatChangedAction(excelNumberFormat),
    );
  };

  const excelNumberFormatUserPref = useAppSelector(
    state => state.ui.userPreferences.excelNumberFormat,
  );

  const navigatorLanguage: ExcelNumberFormat = navigator.language.includes('en') ? 'EN' : 'FR';

  return (
    <div className="d-grid mt-4">
      <span className="me-2 mb-2">Excel Number Format</span>
      <ToggleButtonGroup
        name="excelNumberFormat"
        className="errorable-bloc btn-group-toggle"
        type="radio"
        onChange={changeExcelNumberFormat}
        value={excelNumberFormatUserPref}
      >
        <ToggleButton variant="toggle-primary" value="auto" id="number-format-auto">
          Auto ({navigatorLanguage})
        </ToggleButton>
        <ToggleButton className="d-flex gap-2" variant="toggle-primary" value="EN" id="EN">
          English
          <span className="text-body-tertiary">[ 100,000.99 ]</span>
        </ToggleButton>
        <ToggleButton className="d-flex gap-2" variant="toggle-primary" value="FR" id="FR">
          French
          <span className="text-body-tertiary">[ 100 000,99 ]</span>
        </ToggleButton>
      </ToggleButtonGroup>
    </div>
  );
}
