import { z } from 'zod';
import type { ExcelNumberFormat } from '@/neos/business/ui/userPreferences/userPreferencesUiModel.ts';
import { parseValidExcelNumber } from '@/util/number/numberUtils.ts';

export function transformToNumberIfDefined(
  value: string | undefined,
  ctx: z.RefinementCtx,
  excelNumberFormat: ExcelNumberFormat,
): number | undefined {
  if (value === undefined) {
    return value;
  }

  const parsedValue = parseValidExcelNumber(value, excelNumberFormat);
  if (parsedValue === null) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: `${value} format cannot be parsed`,
    });

    // This is a special symbol used to return early from the transform function.
    // It has type `never` so it does not affect the inferred return type.
    return z.NEVER;
  }
  return parsedValue;
}
