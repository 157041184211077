import type { Selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import type { SecondaryInfo } from '@/neos/business/neosOnyxModel';

export function getSecondaryInfos(
  legId: string,
  allocationId: string,
  rfqId: string,
  state: AppState,
  selectors: Selectors,
): SecondaryInfo | undefined {
  const currentAllocation = selectors.getOtcAllocation(state, rfqId, legId, allocationId);
  return currentAllocation?.secondaryInfo;
}

export function isLegIdPartOfASecondaryStrategy(
  legId: string,
  state: AppState,
  selectors: Selectors,
): boolean {
  const strategyId = selectors.getStrategyIdByLegId(state, legId);
  return selectors.isSecondaryStrategy(state, strategyId, selectors);
}
