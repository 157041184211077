import type { Selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import { isOtcExecutionProduct, type OtcAllocation } from '@/neos/business/neosModel';
import type { SizeType } from '@/neos/business/referenceData/strategyDefinition/strategyDefinitionOnyxModel';
import type { AllocationUi } from '@/neos/business/ui/allocation/allocationUiModel';
import type { DisplayNegotiatedSize } from '@/neos/business/ui/strategy/strategyUiModel';
import type { StateMap } from '@/util/collectionHelper';
import type { SalesMarginAmountInfo } from './OtcAllocation';
import type { AllocBookingInfos } from './OtcAllocations';

export type OtcAllocationsModel = {
  allocations: OtcAllocation[];
  bookingInfos: AllocBookingInfos;
  salesMarginAmountInfos: Record<string, SalesMarginAmountInfo>;
  errors: StateMap<AllocationUi['errors']>;
  ccy: string | undefined;
  firstAllocCcy: string | undefined;
  sizeType?: SizeType;
  uiSizeType: DisplayNegotiatedSize;
  isSecondaryStrategy: boolean;
  isSecondaryNovation: boolean;
};

export function getOtcAllocationsModel(
  appState: AppState,
  rfqId: string,
  strategyId: string,
  legId: string,
  selectors: Selectors,
): OtcAllocationsModel {
  const { productId, sizeType } = selectors.getLegData(appState, legId);
  const uiSizeType = selectors.getDisplayNegotiatedSize(appState.ui, strategyId);
  const product = selectors.getProduct(appState, productId);
  if (!isOtcExecutionProduct(product)) {
    throw new Error('Leg Description component only supports eligible products.');
  }

  const allocations = selectors.getOtcAllocationsByLegId(appState, legId);

  const errors: StateMap<AllocationUi['errors']> = allocations
    .map(({ uuid }) => ({ uuid, errors: selectors.getAllocErrors(appState.ui, uuid) }))
    .reduce((acc, cur) => ({ ...acc, [cur.uuid]: cur.errors }), {});

  const allocationIds = allocations.map(allocation => allocation.uuid);

  const { salesMarginAmountEurByAllocation, clientWay } = selectors.getRfqData(appState, rfqId);

  const salesMarginAmountInfos = Object.entries(salesMarginAmountEurByAllocation ?? {}).reduce(
    (result, [allocId, bidAsk]) => {
      if (!allocationIds.find(id => id === allocId)) {
        return result;
      }

      const { value, unit } =
        clientWay === 'BUY'
          ? { value: bidAsk.ask?.value, unit: bidAsk.ask?.unit }
          : { value: bidAsk.bid?.value, unit: bidAsk.bid?.unit };

      const otcAllocationSalesCreditBookingInfo = selectors.getOtcAllocationSalesCreditBookingStep(
        appState,
        rfqId,
        allocId,
      );

      result[allocId] = {
        value,
        unit,
        status: otcAllocationSalesCreditBookingInfo?.status,
        lastUpdateTime: otcAllocationSalesCreditBookingInfo?.lastUpdateTime,
      };
      return result;
    },
    {} as Record<string, SalesMarginAmountInfo>,
  );

  const bookingInfos: AllocBookingInfos = allocations.map(allocation => {
    const preconfirmationStep = selectors.preConfirmationStepSelectors.find(
      appState.preConfirmationStep,
      { rfqId, uuid: allocation.uuid },
    );
    const isManuallyUpload = preconfirmationStep?.status === 'UPLOADED';
    const bookingStep = selectors.getOtcAllocationBookingStep(appState, rfqId, allocation.uuid);

    const externalId = allocation.externalReferenceId?.id;

    if (externalId) {
      return {
        bookingId: externalId,
        defaultBookingId: bookingStep?.bookingId,
        message: undefined,
        status: undefined,
        isManuallyUpload,
        allocationId: allocation.uuid,
        brokenLink: undefined,
        lastUpdateTime: undefined,
      };
    }

    return {
      bookingId: bookingStep?.bookingId,
      defaultBookingId: bookingStep?.bookingId,
      message: bookingStep?.message,
      status: bookingStep?.status,
      isManuallyUpload,
      allocationId: allocation.uuid,
      brokenLink: bookingStep?.brokenLink,
      lastUpdateTime: bookingStep?.lastUpdateTime,
    };
  });

  const isSecondaryStrategy = selectors.isSecondaryStrategy(appState, strategyId, selectors);

  const isSecondaryNovation = selectors.isSecondaryNovation(appState, legId, selectors);

  return {
    sizeType,
    uiSizeType,
    allocations,
    bookingInfos,
    salesMarginAmountInfos,
    errors,
    ccy: selectors.getCurrencyByStrategyId(appState, strategyId, selectors),
    firstAllocCcy: allocations.length
      ? allocations[0].independantAmountUnit
      : selectors.getCurrencyByStrategyId(appState, strategyId, selectors),
    isSecondaryStrategy,
    isSecondaryNovation,
  };
}
