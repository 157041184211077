import type { Thunk } from '@/bootstrap/thunks';
import { DefaultingScope } from '@/neos/business/rfq/models';
import { DefaultingOptions } from '@/neos/business/rfq/rfqActions';
import { selectors } from '@/bootstrap/selectors.ts';

export function createDefaultBasketProductThunk(rfqId: string): Thunk {
  return function defaultBasketProductThunk(
    dispatch,
    getState,
    {
      selectors: { isStatusAfterBookingRequestedIncluded, isStatusAtOrAfterOrderAccepted },
      actionCreators: {
        neos: { createDefaultRfqRequestedAction },
      },
    },
  ) {
    const state = getState();
    const isStatusAfterBookingRequestedInc = isStatusAfterBookingRequestedIncluded(
      state,
      rfqId,
      selectors,
    );
    const isStatusAfterOrderAcceptedInc = isStatusAtOrAfterOrderAccepted(state, rfqId, selectors);

    const overrideScopes = [DefaultingScope.NEGOTIATED_SIZE, DefaultingScope.NOTIONAL];
    const enrichScopes = [
      DefaultingScope.TRADE_DATE,
      DefaultingScope.REFERENCE,
      DefaultingScope.FOREX,
    ];
    const defaultingScopes = [
      DefaultingScope.ROUNDING_STRATEGY,
      DefaultingScope.UNDERLYING,
      DefaultingScope.REFERENCE_MATURITY,
      DefaultingScope.PIVOT_CURRENCY,
      DefaultingScope.CALCULATION_METHOD,
      DefaultingScope.ROLE_DEFINITION,
      DefaultingScope.BREAK_FEE_ELECTION,
      DefaultingScope.COMPONENT_SECURITY_INDEX_ANNEX,
      DefaultingScope.DIVIDEND_PAYMENT_DATE,
      DefaultingScope.SETTLEMENT_METHOD_ELECTION,
      DefaultingScope.DETERMINATION_METHOD,
      DefaultingScope.DELIVERY_TYPE,
      DefaultingScope.STRIKE_DATE,
      DefaultingScope.MATURITY_DATE,
      DefaultingScope.EFFECTIVE_DATE,
      DefaultingScope.PAYMENT_DELAY,
      DefaultingScope.BREAK_FEE,
    ];
    const isUnitOverride = isStatusAfterOrderAcceptedInc ? false : true;
    const isSettlementOtcOverride = isStatusAfterBookingRequestedInc ? false : true;

    if (isUnitOverride) {
      overrideScopes.push(DefaultingScope.UNITS);
    } else {
      enrichScopes.push(DefaultingScope.UNITS);
    }

    if (isStatusAfterBookingRequestedInc) {
      enrichScopes.push(...defaultingScopes);
      overrideScopes.push(DefaultingScope.SPOT_NET);
    } else {
      enrichScopes.push(DefaultingScope.SPOT_NET);
      overrideScopes.push(...defaultingScopes);
    }

    const defaultingOptions = new DefaultingOptions({
      overrideScopes,
      enrichScopes,
      isSettlementOtcOverride,
    });
    dispatch(createDefaultRfqRequestedAction(rfqId, defaultingOptions));
  };
}
