import type { AppState } from '@/bootstrap/state';
import type { UnderlyingInfo } from '../underlyingInfo/underlyingInfoModel';

export function getUnderlyingInfo(
  state: AppState,
  underlyingId: string | undefined,
): UnderlyingInfo | undefined {
  if (underlyingId) {
    return state.underlyingState[underlyingId];
  }
  return undefined;
}

export function getUnderlyingsInfo(state: AppState, underlyingId: string[]) {
  return underlyingId
    .map(underlyingId => getUnderlyingInfo(state, underlyingId))
    .filter((underlying): underlying is UnderlyingInfo => underlying !== undefined);
}
