import type { SgmeHttp } from '@/util/http/sgmeHttpBase';
import type { Observable } from 'rxjs';
import type { OnyxTransaction } from '@/neos/business/neosOnyxModel.ts';

export interface CreateTopicApi {
  createTopic: (rfqId: string, transaction: OnyxTransaction) => Observable<OnyxTransaction>;
}
export const createCreateTopicApi = (http: SgmeHttp): CreateTopicApi => ({
  createTopic(rfqId: string, transaction: OnyxTransaction) {
    return http.post<OnyxTransaction>({
      url: `api/workflow-neos/${rfqId}/createTopic`,
      body: transaction,
    });
  },
});
