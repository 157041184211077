import type { OnyxRfq } from '@/neos/business/rfq/rfqOnyxModel';

export interface SavedRfq {
  isSavingDone: boolean | null;
  lastSavedRfq: OnyxRfq | undefined;
}

interface NoNotificationRfq {
  isThereNotificationRfq: false;
}

interface WithNotificationRfq {
  isThereNotificationRfq: true;
  lastNotificationRfq: OnyxRfq;
}

export type NotificationRfq = NoNotificationRfq | WithNotificationRfq;

export type NestedRfqs = SavedRfq & {
  notificationRfq: NotificationRfq;
};

export const defaultNestedRfqs: NestedRfqs = {
  isSavingDone: null,
  lastSavedRfq: undefined,
  notificationRfq: { isThereNotificationRfq: false },
};
