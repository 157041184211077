export interface CreateTopicRequestedAction {
  type: 'CREATE_TOPIC_REQUESTED';
  rfqId: string;
}

export function createCreateTopicRequestedAction(rfqId: string): CreateTopicRequestedAction {
  return {
    type: 'CREATE_TOPIC_REQUESTED',
    rfqId,
  };
}
