import { selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import { thunks } from '@/bootstrap/thunks';
import type { ElsClsFlowModeType } from '@/neos/business/neosOnyxModel';
import { useDispatch, useSelector } from 'react-redux';
import { FlowMode } from './FlowMode';

export function RateCurrentFlowMode({
  allocationId,
  rfqId,
  legId,
}: {
  allocationId: string;
  rfqId: string;
  legId: string;
}) {
  const dispatch = useDispatch();

  const currentAllocation = useSelector((state: AppState) =>
    selectors.getOtcAllocation(state, rfqId, legId, allocationId),
  );
  const flow = currentAllocation?.secondaryInfo?.rateCurrentFlowMode;

  const onFlowChange = (value: ElsClsFlowModeType | undefined) => {
    dispatch(
      thunks.neos.createUpdateSecondaryInfoThunk(allocationId, rfqId, legId, {
        rateCurrentFlowMode: value,
      }),
    );
  };

  return (
    <FlowMode
      legId={legId}
      value={flow}
      onFlowChange={onFlowChange}
      dataE2e="rate-current-flow-mode"
    />
  );
}
