import type {
  AbstractConfirmationMedia,
  CommissionType,
  IATypeValuesType,
  MarkitConfirmationMedia,
  PaperConfirmationMedia,
  PriceUnitType,
  Status,
  SubStatus,
} from '@/neos/business/neosModel';
import type { Broker } from '@/neos/business/referenceData/brokers/brokersModel';
import type { StateMap } from '@/util/collectionHelper';
import type { BidAsk, OnyxQuote } from '../quotes/quoteOnyxModel';
import type {
  ActivityType,
  AggregatedDataByPriceUnit,
  Axe,
  DataAggregationByUnderlyingId,
  OnyxCounterparty,
  OnyxQuoteType,
  OnyxRfq,
  PriceWithUnit,
  Source,
  SpreadWidth,
  UnitTypeQuotes,
  Workflow,
} from '../rfqOnyxModel';

export const idbWays = {
  SELL: 'Initiator Buys',
  TWO_WAYS: 'Two Ways',
  BUY: 'Initiator Sells',
} as const;

export const clientWays = {
  SELL: 'Client Sells',
  TWO_WAYS: 'Two Ways',
  BUY: 'Client Buys',
} as const;
export type Way = keyof typeof clientWays;
export type WayValues = (typeof clientWays)[Way];

interface UndefinedPreAllocMediaConfirmationMedia extends AbstractConfirmationMedia {
  media: undefined;
}

export type PreAllocConfirmationMedia = {
  eligibleMedia?: 'MARKITWIRE' | 'LONG_FORM' | 'SHORT_FORM' | 'UNKNOWN';
} & (UndefinedPreAllocMediaConfirmationMedia | MarkitConfirmationMedia | PaperConfirmationMedia);

type StrategyId = string;

export type OtcPreAlloc = {
  counterpartId: number | undefined;
  ratio: number | undefined;
  independantAmountValue: number | undefined;
  independantAmountType: IATypeValuesType | undefined;
  independantAmountUnit: string | undefined;
  markitWireEligible: boolean | undefined;
  mcaEligible: boolean | undefined;
  independantAmountValueDate: string | undefined;
} & PreAllocConfirmationMedia;

export interface ListedPreAlloc {
  clearerAccount: string | undefined;
  broker: OnyxCounterparty | undefined;
  commission: number | undefined;
  commissionType: CommissionType | undefined;
  ratio: number | undefined;
}

export interface RfqData {
  bookingValidation?: { warnings: string };
  internal: boolean | undefined;
  source: Source;
  version: number;
  uuid: string;
  activity?: ActivityType;
  workflow: Workflow;
  clientWay?: Way;
  notionalAmount?: number;
  notionalCurrency?: string;
  notionalInSwapCurrencyAmount?: number;
  notionalInSwapCurrencyCurrency?: string;
  pivotNotionalAmount?: number;
  pivotNotionalCurrency?: string;
  usdNotionalAmount?: number;
  usdNotionalCurrency?: string;
  eurNotionalAmount?: number;
  eurNotionalCurrency?: string;
  status: Status;
  savedStatus?: Status;
  subStatus?: SubStatus;
  strategyIds: string[];
  deltaHedgingStrategyIds: string[];
  comment?: string;
  chatComment?: string;
  clientComment: string | undefined;
  quoteRecap?: string;
  tradeRecap?: string;
  tradeDate?: string | undefined;
  fairPriceId?: string;
  crossBorder?: boolean;
  quoteId: string;
  totalSalesMargin: BidAsk | undefined;
  totalSalesMarginEur: BidAsk | undefined;
  totalPremiums?: BidAsk;
  aggregatedDataByPriceUnit?: StateMap<AggregatedDataByPriceUnit>;
  dataAggregationByUnderlyingId?: StateMap<DataAggregationByUnderlyingId>;
  predealCheckIds: string[];
  otcPreAllocs: OtcPreAlloc[];
  deltaOtcPreAllocs: OtcPreAlloc[];
  listedPreAllocs: ListedPreAlloc[];
  deltaListedPreAllocs: ListedPreAlloc[];
  quoteType: OnyxQuoteType;
  priceRequestUTCDateAndTime: string | undefined;
  screenPrice?: BidAsk;
  orderReceivedUTCTimestamp: string | undefined;
  orderReceivedExpirationUTCTimestamp: string | undefined;
  rfqExpirationUTCTimestamp: string | undefined;
  creationTime: string | undefined;
  hasToConfirmDelta: boolean | undefined;
  hasQuoteExpirationTime: boolean | undefined;
  quoteExpiryUTCTime: string | undefined;
  quoteValidityDuration: number | undefined;
  feedbackPriceValue: number | undefined;
  feedbackPriceUnit: string | undefined;
  feedbackPriceType: PriceUnitType | undefined;
  feedbackSpreadWidth: SpreadWidth | undefined;
  feedbackAxe: Axe | undefined;
  salesCreditBookingReference?: { application: 'XONE'; id: string };
  salesLink: boolean | undefined;
  salesMarginAmountEurByAllocation: Record<string, BidAsk> | undefined;
  quoteInBasis?: OnyxQuote;
  quoteInCurrency?: OnyxQuote;
  quoteInPercent?: OnyxQuote;
  legQuotes?: Record<string, UnitTypeQuotes>;
  strategyQuotes?: Record<StrategyId, UnitTypeQuotes>;
  refSpotInSwapCurrency?: Record<StrategyId, PriceWithUnit>;
  rfqPricingExpirationUTCTimestamp?: string;
  warnings?: OnyxRfq['warnings'];
  manualSalesCredit?: PriceWithUnit;
  initiatedByTrader: boolean | undefined;
  brokerNotInPreferences?: Broker;
}

export const getInitialRfqData = (rfqId: string, isTraderInitiated: boolean = false): RfqData => ({
  internal: undefined,
  uuid: rfqId,
  source: 'VOICE',
  strategyIds: [],
  deltaHedgingStrategyIds: [],
  version: 0,
  clientWay: 'TWO_WAYS',
  quoteId: rfqId,
  status: 'NEW',
  workflow: 'NEOS',
  predealCheckIds: [],
  deltaListedPreAllocs: [],
  otcPreAllocs: [],
  deltaOtcPreAllocs: [],
  listedPreAllocs: [],
  totalSalesMargin: undefined,
  totalSalesMarginEur: undefined,
  quoteType: 'TRADABLE',
  priceRequestUTCDateAndTime: undefined,
  orderReceivedExpirationUTCTimestamp: undefined,
  rfqExpirationUTCTimestamp: undefined,
  creationTime: undefined,
  orderReceivedUTCTimestamp: undefined,
  clientComment: undefined,
  hasToConfirmDelta: undefined,
  hasQuoteExpirationTime: false,
  quoteExpiryUTCTime: undefined,
  quoteValidityDuration: undefined,
  feedbackAxe: 'UNKNOWN',
  feedbackSpreadWidth: 'UNKNOWN',
  feedbackPriceType: undefined,
  feedbackPriceUnit: undefined,
  feedbackPriceValue: undefined,
  salesLink: undefined,
  salesMarginAmountEurByAllocation: undefined,
  notionalInSwapCurrencyCurrency: undefined,
  notionalInSwapCurrencyAmount: undefined,
  initiatedByTrader: isTraderInitiated,
});
