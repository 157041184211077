import { selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import { thunks } from '@/bootstrap/thunks';
import type { Counterpart } from '@/neos/business/neosModel';
import { useDispatch, useSelector } from 'react-redux';
import { CounterPart } from './CounterPart';

export function Transferee({
  allocationId,
  rfqId,
  legId,
}: {
  allocationId: string;
  rfqId: string;
  legId: string;
}) {
  const dispatch = useDispatch();
  const currentAllocation = useSelector((state: AppState) =>
    selectors.getOtcAllocation(state, rfqId, legId, allocationId),
  );
  const counterpart = currentAllocation?.secondaryInfo?.transferee;

  const onChange = (value: Counterpart | undefined) => {
    dispatch(
      thunks.neos.createUpdateSecondaryInfoThunk(allocationId, rfqId, legId, {
        transferee: value,
      }),
    );
  };

  return (
    <CounterPart
      allocationId={allocationId}
      counterpart={counterpart}
      onChange={onChange}
      rfqId={rfqId}
      legId={legId}
    />
  );
}
