import { camelCase } from 'lodash';

export const sanitizeColumnName = (column: string) => camelCase(column).toLowerCase();

export const sanitizeArrayOfColumnNames = (columns: string[]) =>
  columns.map(column => sanitizeColumnName(column));

// These aliases are NOT exhaustive because they are evaluated case-insensitive and space-insensitive in sanitizeColumnName()
export const bloombergCodeColumnAliases = [
  'underlyingName',
  'Bloombergcode',
  'Ticker',
  'Tickers',
  'Underlying',
  'Underlying name',
  'underlyingName',
  'name',
  'bbgName',
  'bbg name',
  'bbg code',
  'bbgCode',
  'bbg',
  'bloom',
  'Basket Underlying',
];

export const quantityColumnAliases = ['quantity', 'quantité', 'qty', 'Basket Quantity'];

export const spotColumnAliases = [
  'spot',
  'price',
  'openprice',
  'open price',
  'closeprice',
  'close price',
  'px',
  'prix',
  'Basket Spot Gross',
];

export const spotUnitAliases = ['Spot Unit'];

export const spotNetAliases = ['Spot Net'];

export const spotNetUnitAliases = ['Spot Net Unit'];

export const counterpartAliases = ['counterpart'];

export const brokerAliases = ['broker'];

export const portfolioAliases = ['portfolio'];

export const internalPortfolioAliases = ['Internal Portfolio', 'Internal Ptf'];

export const typeAliases = ['type'];

export const wayAliases = ['way'];
