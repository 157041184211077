import { selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import { thunks } from '@/bootstrap/thunks';
import type { Counterpart } from '@/neos/business/neosModel';
import { useDispatch, useSelector } from 'react-redux';
import { CounterPart } from './CounterPart';

export function RemainingParty({
  allocationId,
  rfqId,
  legId,
}: {
  allocationId: string;
  rfqId: string;
  legId: string;
}) {
  // can be mapped to either secondary info or step in info depending on the event type

  const dispatch = useDispatch();
  const { eventType } = useSelector((state: AppState) => selectors.getLegData(state, legId));

  const currentAllocation = useSelector((state: AppState) =>
    selectors.getOtcAllocation(state, rfqId, legId, allocationId),
  );
  const counterpart =
    eventType === 'STEPIN'
      ? currentAllocation?.stepInInfo?.remainingParty
      : currentAllocation?.secondaryInfo?.remainingParty;

  const onChange = (value: Counterpart | undefined) => {
    if (eventType === 'STEPIN') {
      dispatch(
        thunks.neos.createUpdateStepInInfoThunk(allocationId, rfqId, legId, {
          remainingParty: value,
        }),
      );
    } else {
      dispatch(
        thunks.neos.createUpdateSecondaryInfoThunk(allocationId, rfqId, legId, {
          remainingParty: value,
        }),
      );
    }
  };

  return (
    <CounterPart
      allocationId={allocationId}
      counterpart={counterpart}
      onChange={onChange}
      rfqId={rfqId}
      legId={legId}
    />
  );
}
