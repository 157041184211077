import { selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import { thunks } from '@/bootstrap/thunks';
import { NeosSelect } from '@/neos/components/share/NeosSelect/NeosSelect';
import { useDispatch, useSelector } from 'react-redux';

export function NovationFeeWay({
  allocationId,
  rfqId,
  legId,
}: {
  allocationId: string;
  rfqId: string;
  legId: string;
}) {
  const dispatch = useDispatch();

  const currentAllocation = useSelector((state: AppState) =>
    selectors.getOtcAllocation(state, rfqId, legId, allocationId),
  );
  const sgWay = currentAllocation?.secondaryInfo?.novationFeeWay;

  const onSgWayChange = (value: 'SG_RECEIVE' | 'SG_PAY' | undefined) => {
    dispatch(
      thunks.neos.createUpdateSecondaryInfoThunk(allocationId, rfqId, legId, {
        novationFeeWay: value ? value : undefined,
      }),
    );
  };

  return (
    <NeosSelect
      value={sgWay}
      onChange={selected => onSgWayChange(selected as 'SG_RECEIVE' | 'SG_PAY')}
      addEmptyOption
      options={[
        { value: 'SG_RECEIVE', label: 'SG Receive' },
        { value: 'SG_PAY', label: 'SG Pay' },
      ]}
      data-e2e={`otc-alloc-novation-fee-way`}
    />
  );
}
