import { otcAllocationDomain } from './otcAllocationDomain';
import type { ChainableAction } from '@/neos/business/neosActions.ts';

export const otcAllocationActionCreators = {
  otcAllocationCrudActions: otcAllocationDomain.actions,
  createAllocRetrieveCashflowsAction,
};

export interface AllocRetrieveCashflowsAction extends ChainableAction {
  type: 'ALLOC_RETRIEVE_CASHFLOWS';
  rfqId: string;
}

function createAllocRetrieveCashflowsAction(rfqId: string): AllocRetrieveCashflowsAction {
  return {
    type: 'ALLOC_RETRIEVE_CASHFLOWS',
    rfqId,
  };
}
