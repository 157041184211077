import type { Thunk } from '@/bootstrap/thunks';

type QuoteRemoveStartLevel = 'STRATEGY' | 'LEG';

export function createRfqResetStrategyQuoteAndFairPricesThunk(
  rfqId: string,
  strategyId: string,
  startLevel: QuoteRemoveStartLevel = 'LEG',
): Thunk {
  return function rfqResetStrategyQuoteAndFairPricesThunk(
    dispatch,
    getState,
    {
      selectors,
      actionCreators: {
        neos: { rfqDataCrudActions },
      },
      thunks: {
        neos: { createRfqResetStrategyQuoteThunk, createRfqResetStrategyFairPriceThunk },
      },
    },
  ) {
    const state = getState();

    const isOtcStrategyAndAmendWorkflowStatus = selectors.isOtcStrategyAndAmendWorkflowStatus(
      state,
      rfqId,
      strategyId,
      selectors,
    );
    const isRfqInitiatedByTrader = selectors.isRfqInitiatedByTrader(state, rfqId);
    const isElsStrategy = selectors.isElsStrategy(state, strategyId, selectors);
    const isElsStrategyInitiatedByTrader = isRfqInitiatedByTrader && isElsStrategy;

    if (isOtcStrategyAndAmendWorkflowStatus || isElsStrategyInitiatedByTrader) {
      return;
    }

    const emptyPremiumAction = rfqDataCrudActions.update(rfqId, { totalPremiums: undefined });
    dispatch(
      emptyPremiumAction,
      createRfqResetStrategyQuoteThunk(rfqId, strategyId, startLevel),
      createRfqResetStrategyFairPriceThunk(rfqId, strategyId, startLevel),
    );
  };
}
