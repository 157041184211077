import type { Selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';

export type CashflowsModel = {
  isDisplayed: boolean;
  indexCashFlow: number | undefined;
  feeCashFlow: number | undefined;
  interestCashFlow: number | undefined;
};

export function getCashflowsModel(
  state: AppState,
  rfqId: string,
  legId: string,
  selectors: Selectors,
): CashflowsModel {
  const otcAllocations = selectors.getOtcAllocationsByLegId(state, legId);
  //NOTE: for secondaries: single allocation per leg
  const allocationId = otcAllocations.length === 1 ? otcAllocations[0].uuid : '';

  const currentAllocation = selectors.getOtcAllocation(state, rfqId, legId, allocationId);

  return {
    isDisplayed: selectors.shouldDisplayOtcAllocationsCashflows(state, rfqId, selectors),
    indexCashFlow: currentAllocation?.secondaryInfo?.indexCashFlow,
    feeCashFlow: currentAllocation?.secondaryInfo?.feeCashFlow,
    interestCashFlow: currentAllocation?.secondaryInfo?.interestCashFlow,
  };
}
