import type { SgmeHttp } from '@/util/http/sgmeHttpBase';
import type { Observable } from 'rxjs';
import type { OnyxRfq } from '@/neos/business/rfq/rfqOnyxModel.ts';

export interface AllocationRetrieveCasfhlowsApi {
  retrieveCashflows: (rfqId: string) => Observable<OnyxRfq>;
}

export const createAllocationRetrieveCashflowsApi = (
  http: SgmeHttp,
): AllocationRetrieveCasfhlowsApi => ({
  retrieveCashflows(rfqId: string): Observable<OnyxRfq> {
    const url = `api/post-trade/secondary/cashflows/${rfqId}`;
    return http.get<OnyxRfq>({
      url,
    });
  },
});
