import type { AppState } from '@/bootstrap/state.ts';
import type { Selectors } from '@/bootstrap/selectors.ts';

export function getAreFileIconsDisplayedModel(
  state: AppState,
  rfqId: string,
  selectors: Selectors,
): boolean {
  const hasPreconfSteps = !!selectors.preConfirmationStepSelectors.selectObjects(
    state.preConfirmationStep,
    { rfqId },
  ).length;

  const isOtcPreConfEnabled = selectors.isFeatureToggleEnabled(state, 'neos.otc.preconf.enabled');
  const isStatusAtOrAfterOrderAccepted = selectors.isStatusAtOrAfterOrderAccepted(
    state,
    rfqId,
    selectors,
  );

  return (hasPreconfSteps && isOtcPreConfEnabled) || isStatusAtOrAfterOrderAccepted;
}
