import type { Dispatchable, Thunk } from '@/bootstrap/thunks.ts';
import type { Els } from '@/neos/business/rfq/strategy/leg/product/productModel.ts';
import { DefaultingScope } from '@/neos/business/rfq/models';
import { DefaultingOptions } from '@/neos/business/rfq/rfqActions';

export function createUpdateElsEquityHedgeComponentUnderlyingThunk(
  product: Els,
  rfqId: string,
  underlyingId: string | undefined,
  index: number,
): Thunk {
  return function updateElsEquityHedgeComponentUnderlying(dispatch, _, { thunks, actionCreators }) {
    if (underlyingId === undefined) {
      return;
    }
    dispatch(
      thunks.neos.createUpdateElsEquityComponentThunk(
        product.uuid,
        index,
        'underlyingId',
        underlyingId,
      ),
    );

    const dispatchable: Dispatchable = actionCreators.neos.createDefaultRfqRequestedAction(
      rfqId,
      new DefaultingOptions({
        enrichScopes: [
          DefaultingScope.REFERENCE,
          DefaultingScope.SPOT_NET,
          DefaultingScope.EQUITY_HEDGE,
          DefaultingScope.FOREX,
        ],
      }),
    );

    dispatch(
      thunks.neos.createNeosLoadUnderlyingInfoAndChangeThunk(rfqId, underlyingId, dispatchable),
    );
  };
}
