import type { ThunkEpic } from '@/bootstrap/epics.ts';
import { type Thunks, thunks } from '@/bootstrap/thunks.ts';
import type { SgmeHttp } from '@/util/http/sgmeHttpBase.ts';
import { ofType } from 'redux-observable';
import { mergeMap } from 'rxjs/operators';
import {
  type AllocationRetrieveCasfhlowsApi,
  createAllocationRetrieveCashflowsApi,
} from '@/neos/business/apis/allocationRetrieveCashflowsApi.ts';
import { wrapInChainableLoadingObservable } from '@/neos/business/epics/wrapInChainableLoadingObservable.ts';

export function getAllocRetrieveCashflowsEpic(http: SgmeHttp) {
  const api = createAllocationRetrieveCashflowsApi(http);
  return createAllocRetrieveCashflowsEpic(api, thunks);
}

export function createAllocRetrieveCashflowsEpic(
  api: AllocationRetrieveCasfhlowsApi,
  { createSuccessToasterThunk, createErrorToasterThunk, neos: { createLoadRfqThunk } }: Thunks,
): ThunkEpic {
  return (action$, _) =>
    action$.pipe(
      ofType('ALLOC_RETRIEVE_CASHFLOWS'),
      mergeMap(({ rfqId, chainOptions }) => {
        return wrapInChainableLoadingObservable({
          tabIds: [rfqId],
          apiObservable: api.retrieveCashflows(rfqId),
          observableOptions: {
            success: {
              on: updatedRfq => [
                createLoadRfqThunk(updatedRfq),
                createSuccessToasterThunk({
                  message: "Successfully retrieved allocation's cash flows.",
                }),
              ],
            },
            error: {
              toaster: {
                creator: (message, error) => createErrorToasterThunk({ message }, error),
                message: "Error when retrieving allocation's cash flows.",
              },
            },
          },
          chainOptions,
        });
      }),
    );
}
