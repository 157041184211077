import { type ActionCreators, actionCreators } from '@/bootstrap/actions';
import type { ThunkEpic } from '@/bootstrap/epics';
import { type Selectors, selectors } from '@/bootstrap/selectors';
import { type Thunks, thunks } from '@/bootstrap/thunks';
import type { RetrieveUnderlyingApi } from '@/neos/business/referenceData/underlying/underlyingEpics';
import type { SgmeHttp } from '@/util/http/sgmeHttpBase';
import { ofType } from 'redux-observable';
import { EMPTY } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { wrapInChainableLoadingObservable } from '../../epics/wrapInChainableLoadingObservable';
import type { OnyxError } from '../../mappers/error';
import { createRetrieveUnderlyingApi } from './underlyingApi';

export function getEquityHedgeCompositionUnderlyingEpic(http: SgmeHttp): ThunkEpic {
  const api = createRetrieveUnderlyingApi(http);
  return equityHedgeCompositionUnderlyingsRequestedEpic(api, actionCreators, thunks, selectors);
}

export function equityHedgeCompositionUnderlyingsRequestedEpic(
  api: RetrieveUnderlyingApi,
  { common: { createAppCrashedAction } }: ActionCreators,
  {
    neos: { createHandleEquityHedgeCompositionUnderlyingsReceivedThunk },
    createErrorToasterThunk,
  }: Thunks,
  selectors: Selectors,
): ThunkEpic {
  return (action$, state$) =>
    action$.pipe(
      ofType('EQUITY_HEDGE_COMPOSITION_UNDERLYING_IDS_REQUESTED'),
      mergeMap(({ rfqId, productId, validatedData, chainOptions }) => {
        if (!selectors.isRfqEls(state$.value, rfqId, selectors)) {
          return EMPTY;
        }

        const bloombergCodes = validatedData.map(element => element.bloombergCode);

        return wrapInChainableLoadingObservable({
          tabIds: [rfqId],
          apiObservable: api.bulkRetrieveUnderlyingByBloombergCodes(bloombergCodes),
          observableOptions: {
            success: {
              on: underlyings => [
                createHandleEquityHedgeCompositionUnderlyingsReceivedThunk(
                  rfqId,
                  productId,
                  validatedData,
                  underlyings,
                ),
              ],
            },
            error: {
              on: (error: OnyxError) => [
                createAppCrashedAction('underlyings-requested', error),
                createErrorToasterThunk(
                  {
                    message: 'Error when retrieving underlyings',
                  },
                  error,
                ),
              ],
            },
          },
          chainOptions,
        });
      }),
    );
}
