import type { UserPreferencesUi } from './userPreferencesUiModel';

export type UserPreferencesState = Readonly<UserPreferencesUi>;

export const defaultUserPreferencesState: UserPreferencesState = {
  showUserPreferencesModal: false,
  showIbChatSection: true,
  showQuickButtonsSection: true,
  quickButtonUnderlyingToggle: 'ASIA',
  quickButtonStrategyToggle: 'ASIA',
  salesPricesToggle: 'DISPLAYED',
  deltaToggle: 'DISPLAYED',
  fairPricesToggle: 'DISPLAYED',
  fairGreeksToggle: 'ON_TOGGLE',
  displayTimeZone: 'LOCAL',
  blotterDefaultTimerange: 'LAST_3_DAYS',
  notificationGroups: [],
  isIndicativeTradableDisplayed: false,
  volBidAskToggle: 'HIDDEN',
  isAlertSoundPlayed: false,
  underlyingsShortcutsList: [],
  strategiesShortcutsList: [],
  searchedCounterparts: [],
  isAddCounterpartPreferenceButtonLoading: false,
  isDeleteCounterpartPreferenceButtonLoading: false,
  isSearchCounterpartPreferenceLoading: false,
  searchedBrokers: [],
  isAddBrokerPreferenceButtonLoading: false,
  isDeleteBrokerPreferenceButtonLoading: false,
  isSearchBrokersPreferenceLoading: false,
  excelNumberFormat: 'auto',
  excelDateFormat: 'auto',
};
