import {
  bloombergCodeColumnAliases,
  quantityColumnAliases,
  sanitizeArrayOfColumnNames,
  sanitizeColumnName,
  spotColumnAliases,
} from '@/neos/business/rfq/strategy/leg/product/els/ElsColumnsImportUtils.ts';

export function elsBasketImportApproximateImportedKeys(
  importedDataWithCamelCaseKeys: Record<string, string>[],
): Record<string, string>[] {
  return importedDataWithCamelCaseKeys.map(line => {
    return Object.fromEntries(
      Object.entries(line).map(([key, value]) => {
        const column = sanitizeColumnName(key);
        if (
          sanitizeArrayOfColumnNames(BasketColumnNameConversions.BLOOMBERG_CODE).includes(column)
        ) {
          return ['bloombergCode', value];
        }
        if (sanitizeArrayOfColumnNames(BasketColumnNameConversions.QUANTITY).includes(column)) {
          return ['quantity', value];
        }
        if (sanitizeArrayOfColumnNames(BasketColumnNameConversions.SPOT).includes(column)) {
          return ['spot', value];
        }

        return [key, value];
      }),
    );
  });
}

// These aliases are NOT exhaustive because they are evaluated case-insensitive and space-insensitive in sanitizeColumnName()
export const BasketColumnNameConversions = {
  BLOOMBERG_CODE: bloombergCodeColumnAliases,
  QUANTITY: quantityColumnAliases,
  SPOT: spotColumnAliases,
};
