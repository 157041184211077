import type { ExcelNumberFormat } from '@/neos/business/ui/userPreferences/userPreferencesUiModel.ts';

const englishFormat = new Intl.NumberFormat('en-EN', {
  maximumFractionDigits: 5,
});

export function formatNum(num?: number): string {
  return num === 0 ? '0' : num && !isNaN(num) ? englishFormat.format(num) : '';
}

export function fixPrecision(n?: number, precision: number = 5) {
  return n === 0 ? 0 : n && Number(n.toFixed(precision));
}

export function parseValidExcelNumber(
  input: string,
  excelNumberFormat: ExcelNumberFormat,
): number | null {
  const trimmedInput = input.trim();

  const parsed =
    excelNumberFormat === 'EN'
      ? parseEnglishNumberFormat(trimmedInput)
      : parseFrenchNumberFormat(trimmedInput);

  if (isNaN(parsed)) {
    return null;
  }

  return parsed;
}

function parseEnglishNumberFormat(input: string): number {
  const isEnglishFormat = input.match(new RegExp(/^(\d+|\d{1,3}(,\d{3})*)(\.\d+)?$/));
  if (isEnglishFormat) {
    if (input.includes(',')) {
      input = input.replace(/,/g, '');
    }

    return parseFloat(input);
  }
  return NaN;
}

function parseFrenchNumberFormat(input: string): number {
  const isFrenchFormat = input.match(new RegExp(/^(\d+|\d{1,3}( \d{3})*)(,\d+)?$/));
  if (isFrenchFormat) {
    input = input.replace(/ /g, '');
    input = input.replace(/,/g, '.');

    return parseFloat(input);
  }
  return NaN;
}
